import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '../../../components/cards/Card';
import Divider from '../../../components/misc/Divider';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import ProfileImage from '../../../components/misc/ProfileImage';
import TickerCompactIcon from '../../../components/misc/TickerCompactIcon';
import Tooltip from '../../../components/popups/Tooltip';
import DetailLabel from '../../../components/shared/detail-section/DetailLabel';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import LogoShort from '../../../resources/images/LogoShort';
import useWindowDimensions, { useStorage, useRemote, useWatchList } from '../../../utils/Utils';

const SectorResearchCard = ({ analysts, companies }) => {
	const { width } = useWindowDimensions();
	const isMobile = width < 768;
	const storage = useStorage()
	const remote = useRemote()
	const navigate = useNavigate()
	const {me, handleWatchListClick} = useWatchList()

	const [companiesWithPrices, setCompaniesWithPrices] = useState([])
	const [companiesWithInfo, setCompaniesWithInfo] = useState([])

	useEffect(() => {
		let tickerCalls = []
		let infoCalls = []

		companies && companies.forEach(company => {
			let func = remote.get("/portal/ticker/" + company.ric)
			tickerCalls.push(func)
		}, []);

		companies && companies.forEach(company => {
			let func = remote.get("/portal/ticker/" + company.ric + "/information")
			infoCalls.push(func)
		}, []);

		Promise.all(tickerCalls).then((tickerResp) => {
			setCompaniesWithPrices(tickerResp)
		})
		
		Promise.all(infoCalls).then((infoResp) => {
			setCompaniesWithInfo(infoResp)
		})
	}, [companies])

	return (
		<PageGridItem fullWidth>
			<Card style={{ height: "100%" }} spacing="l" className="ticker-research-card colour-border-primary">
				<FlexWrapper gap="l" direction={isMobile ? "column" : ""} align="flex-start" fullWidth style={{ height: isMobile ? "auto" : "100%" }}>
					<LogoShort size={32} />
					<FlexWrapper
						fullWidth
						direction={isMobile ? "column" : "row"}
						align="stretch"
						style={{ height: isMobile ? "auto" : "100%", gap: isMobile ? "1.5rem" : 56 }}
					>
						<FlexWrapper direction="column" align="flex-start" gap="s" wrap noShrink>
							{/* Label */}
							<DetailLabel label="Covering Analyst" style={{ width: "100%" }} wrap />
							{/* Analysts */}
							<FlexWrapper direction="row" align="flex-start" fullWidth wrap gap="m">
								{analysts && analysts.length >= 0
									? analysts.map((analyst, index) => {
										if (analyst && analyst.name) {
											return (
											<FlexWrapper key={`ticker-research-card-${index}`} gap="s" onClick={() => analyst && analyst.isAuthor && navigate("/analysts/" + analyst.name)}>
												<ProfileImage tooltip={analyst && analyst.name ? analyst.name : analyst && analyst.isAuthor ?  "-" : "This analyst is no longer available"} src={(analyst && analyst.photo) || null} user={analyst} size="24px" />
											</FlexWrapper>
											);
										}
										return null;
									})
									: "-"}
							</FlexWrapper>
						</FlexWrapper>
						{!isMobile && <Divider vertical flexItem />}
						{/* Tickers/Companies */}
						<FlexWrapper direction="column" align="flex-start" gap="s" wrap>
							{/* Label */}
							<DetailLabel label="Companies Covered" style={{ width: "100%" }} wrap />
							<FlexWrapper direction="row" align="flex-start" fullWidth wrap gap="m">
								{companiesWithPrices && companiesWithPrices.length >= 0
									? companiesWithPrices.map((company, index) => {
										company.ticker.logo = {ticker: company.ticker.ric}
										return (
											<TickerCompactIcon key={index} ticker={company.ticker} prices={company.savedData} target={companiesWithInfo && companiesWithInfo[index] && companiesWithInfo[index]} me={me} setMe={(me) => handleWatchListClick(me)} grouping="sectors"/>
										);
									})
									: "-"}
							</FlexWrapper>
						</FlexWrapper>
					</FlexWrapper>
				</FlexWrapper>
			</Card>
		</PageGridItem>
	);
}

export default SectorResearchCard;
import React from 'react';
import { ExternalLink } from 'react-feather';
import { Outlet } from 'react-router-dom';
import AlertBanner from '../components/shared/prompts/AlertBanner';
import LoginTopBanner from './components/LoginTopBanner';
import "./LoginWrapper.scss";

const LoginWrapper = () => {
	return (
		<div id="login-page-master">
			<LoginTopBanner />
			<AlertBanner hideIcon display><a href="https://portal.craigsip.com/client/login" target="_blank" className="cursor-pointer colour-text-neutral-light" style={{textDecoration: "none"}}>This portal provides access to research intended for institutional investors only. Click Here for our retail investor portal. <ExternalLink style={{cursor: "pointer", marginLeft: 4, display: "inline-block", transform: "translateY(2px)"}} size={16} /></a></AlertBanner>
			{/* Content wrapper */}
			<div className="login-content-wrapper">
				<Outlet />
			</div>
		</div>
	);
}

export default LoginWrapper;
import React, { useEffect } from "react"
import { PageContentBodyGrid } from "../../../layout/grid/page/Page";
import PageGridItem from "../../../layout/grid/page/PageGridItem";
import { useState, useRef } from "react";
import Tabs from "../../buttons/Tabs";
import LoadingIcon from "../../misc/LoadingIcon";
import useWindowDimensions from "../../../utils/Utils";
import FlexWrapper from "../../misc/FlexWrapper";
import "./ResearchSummary.scss";
import { ChevronDown } from "react-feather";

const ResearchSummary = ({ summary, loading }) => {
	const generalInfo = summary && summary.company && summary.company.generalInformation || "No current information";
	const financialInfo = summary && summary.company && summary.company.financialInformation || null;
	const { width } = useWindowDimensions();
	const isMobile = width < 768;
	const [currentView, setCurrentView] = useState("company-overview");
	const [seeMoreInfo, setSeeMoreInfo] = useState(false);
	const [hasOverflow, setHasOverflow] = useState(false);
	const ref = useRef();

	useEffect(() => {
		const overflowing = ref.current.scrollHeight > ref.current.clientHeight;
		setHasOverflow(overflowing);
	  }, [summary, loading]);

	return (
		<PageGridItem col="1 /span 12" className="transition-fade-up-slow">
			<PageContentBodyGrid subGrid rowGap="s">
				<PageGridItem col="1 /span 12">
					<Tabs
						tabs={[
							// {
							// 	label: "Brief",
							// 	onClick: () => {
							// 		setCurrentView("brief");
							// 	},
							// 	active: currentView === "brief",
							// },
							{
								label: "Company Overview",
								onClick: () => {
									setCurrentView("company-overview");
								},
								active: currentView === "company-overview",
							},
						]}
					/>
				</PageGridItem>
				{currentView !== "" && (
					<PageGridItem col="1 / span 12">
						{loading ? (
							<LoadingIcon size={36} centered />
						) : (
							<FlexWrapper direction="column" gap="s" fullWidth align="flex-start">
								<p ref={ref} className={`${seeMoreInfo ? "see-more-info-visible" : ""}${isMobile ? " research-summary-text mb-none mt-m" : ""}`}>
									{currentView === "brief" && financialInfo ? financialInfo : generalInfo}
								</p>
									{isMobile && hasOverflow && <FlexWrapper gap="xs" fullWidth onClick={() => setSeeMoreInfo(!seeMoreInfo)}>
										<p ref={ref} className={`colour-text-neutral-dark-60 m-none`}>{seeMoreInfo ? "See Less" : "See More"}</p>	
										<ChevronDown className="colour-text-neutral-dark-60" size={16} style={{transform: seeMoreInfo ? "rotate(180deg)" : "", transition: "100ms ease"}} />
									</FlexWrapper>}
							</FlexWrapper>
						)}
					</PageGridItem>
				)}
			</PageContentBodyGrid>
		</PageGridItem>
	);
}

export default ResearchSummary;
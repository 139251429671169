import React, { Component, useState } from "react";
import TableImpl from "./TableImpl";

const PerTickerTable = (props) => {

	const {styles, config, getValue, getStyle, tickers} = props;

	const header = config.header ? getValue(config.header) : null;
	const footer = config.footer ? getValue(config.footer) : null;

	const tableData = [];
	const rowStyle = {};

	if (tickers && config.repeatingRows && config.repeatingRows.length > 0) {
		tickers.forEach((ticker, idx) => {
			const hasTickerSpacing = !Number.isNaN(Number.parseInt(config.tickerSpacing));
			const suffix = tickers.length > 1 ? `<${ticker}>` : null;
			const rowNumber = idx + 1;
			const hasMaxRows = !Number.isNaN(Number.parseInt(config.maxTickers));
			const lastRowNumber = hasMaxRows ? Math.min(config.maxTickers, tickers.length) : tickers.length;
			const notLast = rowNumber < lastRowNumber;
			const isFirst = rowNumber === 1;
			const hasHeader = config.headerRow && (isFirst || config.repeatHeader);
			const hasDivider = config.tickerDivider && !!config.horizontalDividerSize && !!config.horizontalDividerColor;

			if (rowNumber <= lastRowNumber) {
				if (hasHeader) {
					tableData.push(config.headerRow.map((val) => getValue(val, suffix)));
					if (hasTickerSpacing && hasHeader && !isFirst) {
						rowStyle[tableData.length - 1] = { paddingTop: `${config.tickerSpacing / 2}pt` };
					}
				}
			}
			if (rowNumber <= lastRowNumber) {
				if (hasTickerSpacing && !hasHeader && !isFirst) {
					rowStyle[tableData.length] = { ...(rowStyle[tableData.length - 1] || {}), paddingTop: `${config.tickerSpacing / 2}pt` };
				}
				config.repeatingRows.forEach(repeatingRow => tableData.push(repeatingRow.map((val) => getValue(val, suffix))));
				if (hasTickerSpacing && notLast) {
					rowStyle[tableData.length - 1] = { ...(rowStyle[tableData.length - 1] || {}), paddingBottom: `${config.tickerSpacing / 2}pt` };
				}
				if (hasDivider && notLast) {
					rowStyle[tableData.length - 1] = { ...(rowStyle[tableData.length - 1] || {}), borderBottom: `${config.horizontalDividerSize}pt solid ${config.horizontalDividerColor}` };
				}
			}
		})
	}

	return <TableImpl config={config} styles={styles} getValue={getValue} getStyle={getStyle} tableData={tableData} header={header} footer={footer} otherRowStyle={rowStyle} />;
}


export default PerTickerTable;

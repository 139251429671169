import React from 'react';
import { ArrowUp } from 'react-feather';
import FlexWrapper from '../misc/FlexWrapper';


const BackToTop = ({href}) => {
	return (
		<FlexWrapper className="back-to-top mt-m" fullWidth gap='s' justify='center' onClick={() => window.location.href = href}>
			<p className="text-align-center font-weight-medium font-size-body-l colour-text-label-grey back-to-top-text">Back to top </p>
			<ArrowUp className="colour-text-label-grey back-to-top-arrow" />
		</FlexWrapper>
	);
}

export default BackToTop;

import React from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import "./FormAlertMessage.scss";

const FormAlertMessage = (props) => {
	const { icon, hideIcon, message, children, type, fullWidth, header, spacing, small, className, justify, inlineIcon, removeBottomMargin } = props;
	return (
		<div className={`form-alert-message-wrapper${className ? ' ' + className : ''}${small ? " form-alert-size-small" : ""}${spacing ? " form-alert-spacing-" + spacing : ""}${type ? " form-alert-type-" + type : ""}${header ? " form-alert-type-has-header" : ""}${removeBottomMargin ? " form-alert-no-margin" : ""}`} style={{width: fullWidth ? "100%" : null, justifyContent: justify}}>
			{/* Icon */}
			{inlineIcon ? "" : hideIcon ? "" : <div className="form-alert-message-icon">{icon ? icon : type === "positive" ? <CheckCircle /> : type === "warning" || type === "danger" ? <AlertCircle /> : ""}</div>}
			{/* Message */}
			<div className={`form-alert-message${inlineIcon ? " text-align-center" : ""}`}>
				{header && <p>{type === "positive" ? "Success" : type === "warning" ? "Warning" : type === "danger" ? "Error" : "Alert"}</p>}
				{hideIcon ? "" : inlineIcon && <div className="form-alert-message-icon inline-icon">{icon ? icon : type === "positive" ? <CheckCircle /> : type === "warning" || type === "danger" ? <AlertCircle /> : ""}</div>}
				{message || children}
			</div>
		</div>
	)
}

export default FormAlertMessage;
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./DateRange.scss";
import DateField from "@threeskye/core-components/dist/components/DateField/DateField";
import { ArrowRight, Calendar, X } from "react-feather";

export const dateRangeIsValid = (fromDate, toDate) => {
	const from = moment(fromDate).isValid() ? moment(fromDate) : null;
	const to = moment(toDate).isValid() ? moment(toDate) : null;
	if (from && to) {
		return from.isBefore(to.add(1, "day"));
	}
	return true;
};

export const dateRangeIsValidAndNonNull = (fromDate, toDate) => {
	const from = fromDate ? moment(fromDate) : null;
	const to = toDate ? moment(toDate) : null;
	if (from && to) {
		return from.isBefore(to.add(1, "day"));
	}
	return false;
};

const DateRange = ({ noLabels, noMargins, from, to, onFromChange, onToChange, onBlur, onClear }) => {
	const [acceptsNonNullPropsValues, setAcceptsNonNullPropsValues] = useState(true)
	const validatedFrom = moment(from).isValid() ? from : null
	const validatedTo = moment(to).isValid() ? to : null

	const getValidity = () => {

		return dateRangeIsValid(from, to);
	}

	const isValid = getValidity();
	const asClassName = (list) => list.filter((cn) => !!cn).join(" ") || undefined

	useEffect(() => {
		let startDay = document.getElementById('start-day');
		let startMonth = document.getElementById('start-month');
		let startYear = document.getElementById('start-year');
		let endDay = document.getElementById('end-day');
		let endMonth = document.getElementById('end-month');
		let endYear = document.getElementById('end-year');
		let startContainer = document.getElementById('start-date-field-outer')
		let endContainer = document.getElementById('end-date-field-outer')

		const trackedElements = [startDay, startMonth, startYear, endDay, endMonth, endYear]

		const handleStartDayKeyDown = (event) => {
			if (event.key === "Enter") {
				const nextInput = event.target.nextElementSibling;
				if (nextInput) {
					nextInput.focus();
				} else if (event.target === startYear) {
					endDay.focus();
				}
			}
		};
		const handleEndYearKeyDown = (event) => {
			if (event.key === "Enter") {
				event.target.blur();
			}
		};

		startContainer.addEventListener("focusout", (e) => e.stopPropagation())
		endContainer.addEventListener("focusout", (e) => e.stopPropagation())

		trackedElements.forEach(element => {
			element.id === "end-year"
				? element.addEventListener('keydown', handleEndYearKeyDown)
				: element.addEventListener('keydown', handleStartDayKeyDown)
			element.addEventListener('click', () => { element.select() })
		})

		setTimeout(() => setAcceptsNonNullPropsValues(false), 500)
		return () => {
			startContainer.removeEventListener("focusout", (e) => e.stopPropagation())
			endContainer.removeEventListener("focusout", (e) => e.stopPropagation())
			trackedElements.forEach(element => {
				element.id === "end-year"
					? element.removeEventListener('keydown', handleEndYearKeyDown)
					: element.removeEventListener('keydown', handleStartDayKeyDown)
				element.removeEventListener('click', () => { element.select() })
			})
		}
	}, [])

	return (
		<div className={asClassName(["date-range-inputs", noLabels && "no-labels", noMargins && "no-margins", !isValid && "invalid"])} tabIndex="-1" onBlur={onBlur}>
			<DateField
				IconOverride={<Calendar style={{ width: "1rem", paddingBottom: "3px" }} />}
				placeholder={{ day: " ", month: " ", year: " " }}
				label={noLabels ? "" : "From"}
				date={validatedFrom}
				onChange={onFromChange}
				stateShouldUpdateWhenPropsChange
				acceptsNonNullPropsValues={acceptsNonNullPropsValues}
				id={'start'}
			/>
			<ArrowRight className="date-range-arrow" />
			<DateField
				IconOverride={<Calendar style={{ width: "1rem", paddingBottom: "3px" }} />}
				placeholder={{ day: " ", month: " ", year: " " }}
				label={noLabels ? "" : "To"}
				date={validatedTo}
				onChange={onToChange}
				stateShouldUpdateWhenPropsChange
				acceptsNonNullPropsValues={acceptsNonNullPropsValues}
				id={'end'}
			/>
			<div style={{ width: "36px" }} className={(validatedFrom || validatedTo) ? "" : "clear-dates-disabled"}>
				<div className="clear-dates-container" onClick={() => (validatedFrom || validatedTo) ? onClear() : null} >
					<X className="clear-dates-icon" />
				</div>
			</div>
		</div>
	);
}

export default DateRange;

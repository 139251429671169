import React, { useEffect, useState } from 'react';
import TableImpl from './TableImpl';

const FlexiDataTable = ({ config, styles, getValue, getStyle }) => {

	const [hasData, setHasData] = useState(false)
	const [noDataMessage, setNoDataMessage] = useState("")
	const [tableData, setTableData] = useState("")

	const header = config.header ? getValue(config.header) : null;
	const footer = config.footer ? getValue(config.footer) : null;
	const rowStyle = {};

	useEffect(() => {const data = getValue(config.data);
	if (!data || data.length === 0) {
		setHasData(false)
		setNoDataMessage(getValue(config.noDataText))
	} else {
		const innerScopeTableData = [];
		const cells = data.split("|");
		const columnCount = config.columnWidths.length;
		let i = columnCount;
		let rowData;
		cells.forEach(cell => {
			if (i === columnCount) {
				rowData = [];
				innerScopeTableData.push(rowData);
				i = 0;
			}

			rowData.push(cell);
			++i;
		});
		setHasData(true)
		setTableData(innerScopeTableData);
	}}, [])

	if (hasData) {
		return <TableImpl
			config={config}
			styles={styles}
			getValue={getValue}
			getStyle={getStyle}
			tableData={tableData}
			header={header}
			footer={footer}
		/>
	} else {
		const titleStyle = getStyle(styles[config.headerStyle]);
		const valueStyle = getStyle(styles[config.defaultCellStyle]);
		titleStyle.width = config.width + "pt";
		valueStyle.width = config.width + "pt";

		return <div>
			<div style={titleStyle}>{header}</div>
			<div style={valueStyle}>{noDataMessage}</div>
		</div>

	}
}

export default FlexiDataTable;
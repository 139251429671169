import React from 'react';
import { LogOut } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import Divider from '../../components/misc/Divider';
import ProfileImage from '../../components/misc/ProfileImage';
import PopupMenu from '../../components/popups/PopupMenu';
import PopupMenuItem from '../../components/popups/PopupMenuItem';
import "./ProfileMenu.scss";
import { analytics, useRemote } from '../../utils/Utils';

const ProfileMenu = ({ user }) => {
	const remote = useRemote()
	const navigate = useNavigate();
	return (
		<>
			<PopupMenu padding width="208px">
				<div className="profile-menu-header-container">
					<ProfileImage user={user} />
					<div className="profile-menu-details">
						<div className="profile-header">{user.firstName} {user.lastName}</div>
					</div>
				</div>
				<Divider />
				<PopupMenuItem label="Settings" onClick={() => navigate("/settings")} />
				<Divider />
				<PopupMenuItem label="Logout" icon={<LogOut />} onClick={() => {
					analytics.track("logOut").then(() => {
						navigate("/")
						remote.logout()
					})
				}} />
			</PopupMenu>
		</>
	)
}

export default ProfileMenu;
import React, { useState } from 'react';
import Card from '../../../components/cards/Card';
import Divider from '../../../components/misc/Divider';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import ProfileImage from '../../../components/misc/ProfileImage';
import DetailField from '../../../components/shared/detail-section/DetailField';
import DetailLabel from '../../../components/shared/detail-section/DetailLabel';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import LogoShort from '../../../resources/images/LogoShort';
import useWindowDimensions, { useStorage } from '../../../utils/Utils';
import { useNavigate } from 'react-router-dom';

const PublicationAssetInfoCard = ({ editMode, ticker, analysts, extraInfo, compact, isDocument }) => {
	const [formattedDate, setFormattedDate] = useState("")
	const { width } = useWindowDimensions();
	const isMobile = width < 768;
	const navigate = useNavigate()

	const price = extraInfo && extraInfo.price && extraInfo.price.price && parseFloat(extraInfo.price.price).toFixed(2) || "-";
	const targetPrice = () => {
		if (ticker && ticker.targetPrice) {
			try {
				let number = parseFloat(ticker.targetPrice).toFixed(2)
				return number
			} catch {
				return "n/a"
			}
		} else {
			return "n/a"
		}
	}
	const priceAtLabel = extraInfo && extraInfo.price && extraInfo.price.dateTime || "";

	return (
		<PageGridItem fullWidth>
			<Card style={{ height: "100%" }} spacing="l" className="ticker-research-card colour-border-primary">
				<FlexWrapper gap={compact ? "l" : "xl"} direction={isMobile ? "" : ""} align="flex-start" fullWidth style={{ height: isMobile ? "auto" : "100%" }}>
					<LogoShort size={32} />
					<FlexWrapper fullWidth direction={isMobile ? "column" : "row"} align="stretch" style={{ height: isMobile ? "auto" : "100%", gap: (isMobile || compact) ? "1.5rem" : 56 }}>
						<FlexWrapper direction="column" align="flex-start" gap="s" wrap noShrink>
							{/* Label */}
							<DetailLabel label="Covering Analyst" style={{ width: "100%" }} wrap />
							{/* Analysts */}
							<FlexWrapper direction={(isMobile || compact) ? "row" : "column"} align="flex-start" fullWidth wrap gap="m">
								{analysts && analysts.length >= 0 ? analysts.map((analyst, index) => {
								return (<FlexWrapper style={{cursor: analyst ? 'pointer' : 'default'}} key={`ticker-research-card-${index}`} gap="s" onClick={() => analyst && analyst.name && navigate("/analysts/" + analyst.name)}>
									<ProfileImage style={{cursor: analyst ? 'pointer' : 'default'}} tooltip={analyst && analyst.name ? analyst.name : analyst && analyst.isAuthor ?  "-" : "This analyst is no longer available" } src={analyst && analyst.photo || null} user={analyst} size="24px" />
									{!compact && <p className="font-size-header-3 transition-fade-in" style={{margin: 0}}>{analyst && analyst.name}</p>}
								</FlexWrapper>)
							}) : "-"}
							</FlexWrapper>
						</FlexWrapper>
						{!isDocument && !isMobile && <Divider vertical flexItem />}
						{!isDocument && <FlexWrapper className="ticker-research-ratings" direction={(isMobile || compact) ? "row" : "column"} align="flex-start" gap={isMobile ? "l" : "xl"} wrap>
							<DetailField label="Current Rating" value={ticker.currentRating} valueClassName="font-size-header-3" />
							<DetailField label={`Price (Delayed)`} value={price} valueClassName="font-size-header-3" />
							<DetailField label="Target Price" value={targetPrice()} valueClassName="font-size-header-3" />
						</FlexWrapper>}
					</FlexWrapper>
				</FlexWrapper>
			</Card>
		</PageGridItem>
	);
}

export default PublicationAssetInfoCard;
import React, { useEffect, useState } from 'react';
import { BookOpen, ExternalLink, FileText, Mail } from 'react-feather';
import Table from '../lists-and-tables/Table';
import useWindowDimensions, { formatDate, formatDateTime, getAnalystImage, onDocumentClick, useStorage, getAnalystName, analytics, downloadDocuments, getAnalyst } from '../../utils/Utils';
import DetailSectionHeader from '../shared/detail-section/DetailSectionHeader';
import SuggestionMessage from '../shared/prompts/SuggestionMessage';
import NoResultsMessage from '../shared/prompts/NoResultsMessage';
import TableActionIcon from './TableActionIcon';
import IconCounter from '../icons/IconCounter';
import FlexWrapper from '../misc/FlexWrapper';
import PageGridItem from '../../layout/grid/page/PageGridItem';
import ProfileImage from '../misc/ProfileImage';
import XlsIcon from '../icons/XlsIcon';
import MobileResearchList from './MobileResearchList';
import { useNavigate } from 'react-router-dom';
import UnreadIndicator from '../icons/UnreadIndicator';
import SearchTermFilter from '../inputs/SearchTermFilter'
import PdfIcon from '../icons/PdfIcon';
import Tooltip from '../popups/Tooltip';


const MiniResearchList = ({ data, suggestionText, restrictHeight, listHeader, showAnalyticsIcons, selectResearch, sticky, grouping, hideImage, hideWatchlistIcon }) => {

	const [hasSearchTerm, setHasSearchTerm] = useState(false)
	const [filteredData, setFilteredData] = useState([])
	const [analysts, setAnalysts] = useState([]);

	const { width, height } = useWindowDimensions();
	const responsiveColWidth = width > 1200 && width < 1430;
	const colWidths = showAnalyticsIcons ? [25 + "%", responsiveColWidth ? "30%" : "60%", "15%", "10%"] : ["15%", "50%", "20%", "12%"];
	const storage = useStorage();
	const navigate = useNavigate()

	useEffect(() => {
		storage.getOrFetch('/portal/analysts').then(setAnalysts);
	})

	const dataMap = (array) => array && array.map((research, idx) => {
		const datafiles = research && research.models ? research.models : [];
		const defaultPdf = (research && research.files && research.files.filter(f => f.defaultPdf)) || [];
		const analystName = getAnalystName(analysts, research.analystName)
		const analystImage = getAnalystImage(analysts, research.analystName)
		const analyst = getAnalyst(analysts, research)

		const delivered = research.receivedDate;
		const open = research.openedDate;
		const downloaded = research.downloadedDate;
		const unread = !research.read;

		return (
			<tr key={idx} onClick={() => selectResearch(research)} className={`cursor-pointer${unread ? " unread-research" : ""}`}>
				<td>
					{research && research.publishedDate && formatDate(research.publishedDate)}
				</td>
				<td style={{ paddingRight: "8rem" }}>
					<div className="truncate research-title" style={{ paddingLeft: unread ? 10 : 0, display: "flex", alignItems: "center", gap: "0.25rem" }}>
						{unread && <UnreadIndicator size={6} className="mr-xs" style={{ position: "absolute", top: "48%", transform: "translateY(-50%)", left: 0 }} />}
						{research.hasHtmlVersion === false && <div ><PdfIcon width="16" height="16" noHover /></div>}
						{research.title ? research.title : "-"}
					</div>
				</td>
				{analyst && analyst.isAuthor
					? <>
						<td onClick={(e) => { e.stopPropagation(); navigate("/analysts/" + research.analystName) }}>
							<FlexWrapper align="center" gap="s">
								<ProfileImage src={analystImage} name={research.analystName} />
								{research.analystName ? research.analystName : null}
							</FlexWrapper>
						</td>
					</>
					: <>
						<td onClick={(e) => { e.stopPropagation(); }} style={{cursor: 'default'}}>
							<FlexWrapper align="center">
								<Tooltip label="This analyst is no longer available.">
									<FlexWrapper align="center" gap="s">
										<ProfileImage style={{cursor: 'default'}} src={analystImage} name={research.analystName} />
										{research.analystName ? research.analystName : null}
									</FlexWrapper>
								</Tooltip>
							</FlexWrapper>
						</td>
					</>
				}
				{showAnalyticsIcons && <td>
					<FlexWrapper gap="m">
						<IconCounter
							iconOnly
							inactive={!open}
							tooltip={open ? `Opened at ${formatDateTime(open)}` : "Not opened"}
							status="read"
							icon={<Mail />}
							dataAvailable={true}
						/>
						<IconCounter
							iconOnly
							inactive={!downloaded}
							tooltip={downloaded ? `Read at ${formatDateTime(downloaded)}` : "Not downloaded"}
							status="read" icon={<FileText />}
							dataAvailable={true}
						/>
					</FlexWrapper>
				</td>}
				<td className="table-action-icons">
					<TableActionIcon
						disabled={defaultPdf.length === 0}
						onClick={(e) => {
							downloadDocuments(e, defaultPdf)
							analytics.track("downloadPDF", { asset: research.coveredShortName, publication: research })
						}}
						icon={<ExternalLink width="24px" />}
						toolTip={defaultPdf.length === 0 ? "No PDF" : "Open PDF"}
					/>
					<TableActionIcon
						disabled={research?.models?.length === 0}
						onClick={(e) => {
							downloadDocuments(e, research.models, true)
							analytics.track("downloadData", { asset: research.coveredShortName, publication: research })
						}}
						icon={<XlsIcon />}
						toolTip={research?.models?.length === 0 ? "No Model" : "Model (" + research?.models?.length + ")"}
					/>
				</td>
			</tr>
		);
	})

	const researchLength = data && data.length;
	const noData = !data || researchLength <= 0;
	const noSearchResults = false;

	return (
		<>
			<PageGridItem
				col="1 / span 12"
				justifyContent="between"
				gap={width >= 900 ? "m" : "l"}
				wrap
				rowSpan="1"
				className="pb-l colour-bg-neutral-light"
				style={{ position: sticky ? "sticky" : "", top: sticky ? (width >= 900 ? -26 : 0) : "", zIndex: 1, paddingTop: sticky ? 16 : "" }}
			>
				<DetailSectionHeader icon={<BookOpen />} header={`${listHeader ? listHeader : "History"}`} />
				{!noData &&
					<SearchTermFilter
						width={width >= 900 ? "m" : ""}
						fullWidth={width <= 900}
						placeholder={`Search History`}
						size="small"
						dataSet={data}
						setFilteredData={setFilteredData}
						path={["author", "ticker", "title", "publishedDate"]}
						hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
					/>
				}
			</PageGridItem>
			<PageGridItem col="1 / span 12" rowSpan="3">
				{noData ? (
					<SuggestionMessage message={<>{suggestionText ? suggestionText : "No published documents to display."}</>} />
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : width > 900 && height > 490 ? (
					<Table className="research-table" restrictHeight={restrictHeight} colWidths={colWidths} dataMap={dataMap(hasSearchTerm ? filteredData : data)} />
				) : (
					<MobileResearchList hideWatchlistIcon={hideWatchlistIcon} onClick={selectResearch} research={hasSearchTerm ? filteredData : data} grouping={grouping} hideImage={hideImage} />
				)}
			</PageGridItem>
		</>
	);
}

export default MiniResearchList;
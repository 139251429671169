import React, {  } from 'react';
import Page, { PageContentBodyGrid } from '../../../layout/grid/page/Page';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'react-feather';
import FlexWrapper from '../../../components/misc/FlexWrapper';

const ErrorPage = ({header, message, icon, noIcon}) => {
	const navigate = useNavigate();	
	return (
		<Page>
			<PageContentBodyGrid rowGap="xl">
				<PageGridItem fullWidth>
					<FlexWrapper gap="m" justify="center" direction="column">
						<FlexWrapper gap="m" align="center" >
							{noIcon ? "" : icon ? icon : <AlertCircle size={32} className="colour-text-primary"  />}
							<h1 className="font-weight-semi-bold">{header ? header : "Something Went Wrong"}</h1>
						</FlexWrapper>
						<p className="font-size-body-l text-align-center">{message ? message : <>Looks like an error occurred. Try going back to the <span className="colour-text-primary cursor-pointer" onClick={() => navigate("/")}>homepage</span>.</>}</p>
					</FlexWrapper>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default ErrorPage;
import React, { Component } from "react";
import "./SimpleTable.scss";
import TableImpl from "./TableImpl";

const OptionalRowTable = (props) => {

	const {getStyle, getValue, getLocalValue, styles, configuration : config, dataSuffix} = props;

	const dataName = config.dataName + (dataSuffix ? dataSuffix : '');
	const selectedString = getLocalValue(dataName);

	const selected = selectedString ? JSON.parse(selectedString) : config.defaultRows;

	const data = [];
	if (config.headerRow) {
		const cell = config.headerRow;
		const rowData = [];
		data.push(rowData);
		cell.forEach(val=>{
			rowData.push(getValue(val.value))
		});
	};
	config.optionalRows.forEach(option=>{
		if (selected.includes(option.option)) {
			const cell = option.values;
			const rowData = [];
			data.push(rowData);
			cell.forEach(val=>{
				rowData.push(getValue(val.value))
			});
		}
	});

	return <>
		<TableImpl
			config={config}
			styles={styles}
			getValue={getValue}
			getStyle={getStyle}
			tableData={data}
			header={config.header && getValue(config.header.value)}
			footer={config.footer && getValue(config.footer.value)}
		/>
		</>;
		
	}


export default OptionalRowTable;

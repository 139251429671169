import { Divider } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getImage, useStorage, goToResearch } from '../../utils/Utils';
import FlexWrapper from '../misc/FlexWrapper';
import StatusToken from '../misc/StatusTokens';
import TickerSectorImage from '../misc/TickerSectorImage';
import "./ResearchList.scss";

const ResearchListRow = ({ data, unread }) => {
	const [tickers, setTickers] = useState([]);
	const storage = useStorage();

	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers);
	}, [])

	const navigate = useNavigate()
	const { title, publishedDate, coveredShortName, author, coveredName, publicationId, imageUrl, analystName, summary } = data;

	const author1 = (data.savedData && data.savedData && data.savedData["Author 1"]) || author || "Unknown"
	const formattedDate = format(new Date(publishedDate), 'dd/MM/yyyy');

	const preview = (data.savedData && data.savedData && data.savedData["Preview"]) || "";

	let link = publicationId || data.publicationId;

	const tickerObj =  tickers ? tickers.find(t => t.ric === coveredShortName) : null;
	const displayName = coveredName ? coveredName : coveredShortName ? tickerObj ? tickerObj.name : coveredShortName : ""

	return (
		<FlexWrapper onClick={() => goToResearch(data, navigate)} className={`research-list-row${unread ? " research-unread" : ""}`} fullWidth justify="space-between">
			<FlexWrapper className="research-content-wrapper" wrap gap="m" style={{ width: "90%" }}>
				{/* header */}
				<div style={{ width: "100%", minHeight: "0.85rem" }} className="research-ticker-name">
					{displayName}
				</div>
				{/* main */}
				<FlexWrapper gap="xs" wrap className="research-info">
					<div style={{ width: "100%" }} className="research-title">
						{title}
					</div>
					<div style={{ width: "100%" }} className="research-preview">
						{summary}
					</div>
				</FlexWrapper>
				{/* details */}
				<FlexWrapper className="research-details" fullWidth gap="s">
					<div className="research-ticker-date">{formattedDate}</div>
					<Divider orientation="vertical" flexItem />
					<div className="research-ticker-author">{analystName}</div>
					<Divider orientation="vertical" flexItem />
					<StatusToken>{coveredShortName}</StatusToken>
				</FlexWrapper>
			</FlexWrapper>
			{/* Logo */}
			<div className="research-ticker-logo">
				<TickerSectorImage image={imageUrl} size="xxl" />
			</div>
		</FlexWrapper>
	);
}

const ResearchList = ({ research, forwardRef }) => {
	if (!research) {
		return null;
	}

	return <div className="research-list-wrapper" ref={forwardRef && forwardRef}>
		{research && research.map((re, idx) => {
			const unread = !(re.read);
			return <ResearchListRow data={re} key={idx} unread={unread} />
		})}
	</div>;
}

export default ResearchList;
import React from 'react';
import { PageContentBodyGrid } from '../../layout/grid/page/Page';
import PageGridItem from '../../layout/grid/page/PageGridItem';
import Card from '../../components/cards/Card';
import "./DetailCard.scss";
import useWindowDimensions from '../../utils/Utils';

const DetailCard = ({ children, className, id }) => {
	const { width } = useWindowDimensions();
	const isMobile = width < 768;

	return (
		<PageGridItem fullWidth>
			<Card id={id} style={{ height: "100%" }} spacing="l" className={`detail-card transition-default${className ? ' ' + className : ''}`}>
				<PageContentBodyGrid subGrid gap={isMobile ? "l" : "xl"} gridTemplateRows="1fr" gridColCount="12">
					{children}
				</PageContentBodyGrid>
			</Card>
		</PageGridItem>
	);
}

export default DetailCard;
import React, { useState } from 'react';
import { Menu } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import Logo from '../../../components/misc/Logo';
import MobileSearchButton from './MobileSearchButton';
import "./MobileTopBar.scss";

const MobileTopBar = ({ menuClick, onSearchClick, searching}) => {
	const navigate = useNavigate();
	
	return (
		<FlexWrapper id="mobile-top-bar" justify="space-between" className="colour-bg-neutral-dark" fullWidth>
			<Menu className="menu-icon transition-fade-in-slow" onClick={menuClick} />
			<Logo className="transition-fade-in-slow" width={90} onClick={() => navigate("/")} />
			<MobileSearchButton searching={searching} onClick={onSearchClick} />
		</FlexWrapper>
   );
}

export default MobileTopBar;
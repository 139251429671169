import moment from "moment";
import React, { Component, useState } from "react";

const LibraryDocsContext = React.createContext({});

export const LibraryDocsWrapper = ({ children }) => {

	const [ticker, setTicker] = useState(null);
	const [from, setFrom] = useState(moment().subtract(1, "year").format("yyyy-MM-DD"));
	const [to, setTo] = useState(moment().format("yyyy-MM-DD"));

	const setFilters = (filters) => {
		const oldFilters = { from, to, ticker };

		if (filters.from !== oldFilters.from) {
			setFrom(filters.from)
		}
		if (filters.to !== oldFilters.to) {
			setTo(filters.to)
		}
		if (filters.ticker !== oldFilters.ticker) {
			setTicker(filters.ticker)
		}
	}

	return <LibraryDocsContext.Provider
		value={{
			libraryDocsFilters: { ticker, from, to },
			setLibraryDocsFilters: setFilters
		}}>
		{children}
	</LibraryDocsContext.Provider>
}

export const withLibraryDocsFilters = function (WrappedComponent) {
	return class extends Component {
		render() {
			return (
				<LibraryDocsContext.Consumer>{({ libraryDocsFilters, setLibraryDocsFilters }) => <WrappedComponent {...this.props} {...{ libraryDocsFilters, setLibraryDocsFilters }} />}</LibraryDocsContext.Consumer>
			);
		}
	};
};

import React from 'react';
import "./TextArea.scss";
import TextFieldLabel from './TextFieldLabel';

const TextArea = (props) => {
	const { className, cols, rows, children, label, placeholder, resize } = props;
	return (
		<div className="text-area-container">
			{label && <TextFieldLabel>{label}</TextFieldLabel>}
			<textarea style={{resize: resize ? resize : "none"}} placeholder={placeholder ? placeholder : "Text...."} className={`text-area-field${className ? " " +  className : ""}`} cols={cols ? cols : "30"} rows={rows ? rows : "5"}>
				{children}
			</textarea>
		</div>
	)
}

export default TextArea;
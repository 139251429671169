import React from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import "./AlertBanner.scss";

// Alert banner will only display if #master-grid has "grid-alert-banner-active" class attached.

const AlertBanner = (props) => {
	const { icon, hideIcon, message, children, type, fullWidth, display, style } = props;
	return (
		<div className={`alert-banner-wrapper${type ? " alert-type-" + type : ""}`} style={{...style, width: fullWidth ? "100%" : null, display: display ? "flex" : ""}}>
			{/* Icon */}
			{hideIcon ? "" : <div className="alert-banner-icon">{icon ? icon : type === "positive" ? <CheckCircle /> : type === "warning" || type === "danger" ? <AlertCircle /> : ""}</div>}
			{/* Message */}
			<div className="alert-banner-message text-align-center">{message || children}</div>
		</div>
	)
}

export default AlertBanner;
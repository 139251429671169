import React from 'react';
import FlexWrapper from '../misc/FlexWrapper';
import "./MobileTabs.scss";

export const Tab = ({label, children, onClick, active}) => {
	return <div className={`mobile-tab${active ? " tab-active" : ""}`} onClick={onClick}>{label || children}</div>;
}

const MobileTabs = ({tabs}) => {
	return (
		<FlexWrapper className="mobile-tab-group">
			{tabs && tabs.map((tab, idx) => <Tab key={idx} onClick={tab.onClick} active={tab.active}>{tab.label}</Tab>)}
		</FlexWrapper>
   );
}

export default MobileTabs;
import React, { useEffect, useState } from 'react';
import TickerSectorItem from '../misc/TickerSectorItem';
import { useNavigate } from 'react-router-dom';
import Table from './Table';
import { getIdentifyingValue, getPortalSearchExtensionLabel, useStorage, watched } from '../../utils/Utils';
import { AnalystDetails } from '../cards/AnalystCard';

const TickerList = ({ tickers, allWatched, onClick, me }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false)

	const colWidths = ["100%"]

	const navigateByGrouping = (data) => {
		const group = data.grouping
		switch (group) {
			case "analysts":
				data.name ? navigate(`/analysts/${data.name}`) : navigate(`/analysts`)
				break;
			case "tickers":
				data.ric && navigate(`/company/${data.ric}`) 
				break;
			case "sectors":
				data.path && navigate(data.path) 
				break;

			default:
				break;
		}
	}

	const dataMap = (dataToMap) => dataToMap && dataToMap.map((ticker, idx) => {
		if (!ticker) {
			return null;
		}
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => navigateByGrouping(ticker)}>
				<td>
					{ticker.grouping === "analysts"
						? <AnalystDetails
							data={ticker}
							onWatchlist={allWatched || (me && watched(me, ticker.ric)) ? true : false}
							watchlistLayout
							onClick={(me) => onClick(me)}
						/>
						: <TickerSectorItem
							assetId={ticker.grouping ? getIdentifyingValue({ type: ticker.grouping, value: ticker }) : ticker.ric}
							onWatchlist={allWatched || (me && watched(me, getIdentifyingValue({ type: ticker.grouping, value: ticker }), ticker.path)) ? true : false}
							unread={ticker.unread}
							isMobile
							label={ticker.name}
							extensionLabel={ticker.grouping ? getPortalSearchExtensionLabel({ type: ticker.grouping, value: ticker }) : ticker.ric}
							image={ticker.logo}
							key={idx}
							onClick={(me) => onClick(me)}
							grouping={ticker.grouping ? ticker.grouping : "tickers"}
							path={ticker.path}
						/>}
				</td>
			</tr>
		);
	})

	return (
		<Table
			colWidths={colWidths}
			dataMap={dataMap(tickers)}
			noResults={{ message: "No tickers found", subMessage: "Try adding a new ticker." }}
			dataLoading={loading}
		/>
	);
}

export default TickerList;
import React from 'react';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import { PageContentBodyGrid } from '../../../layout/grid/page/Page';
import TickerPageDetailsCard from './TickerPageDetailsCard';
import TickerResearchCard from './TickerResearchCard';
import ResearchSummary from '../../../components/shared/research/ResearchSummary';
import useWindowDimensions from '../../../utils/Utils';

const CompanyHeaderCards = ({company, extraInfo, extraInfoLoading, noResearchPublished, setCompactView, compactView, editMode, targetPrice, currentRating, coveringAnalysts}) => {
	const { width } = useWindowDimensions()
	const isMobile = width < 768;

	return (
		<>
			{/* grid container for the ticker details section */}
			<PageGridItem col={width > 1300 ? "1 / span 6" : "1 / span 12"} className="company-page-ticker-details-card">
				<PageContentBodyGrid style={{ height: "100%", gridAutoRows: "auto" }} rowGap="xl" gridColCount="6" subGrid>
					{/* General Details */}
					{company && company.ticker && <TickerPageDetailsCard extraInfo={extraInfo} extraInfoLoading={extraInfoLoading} hideWatchlistIcon={noResearchPublished} infoIconOnClick={() => setCompactView(!compactView)} compact={compactView} ticker={company.ticker} editMode={editMode} />}
				</PageContentBodyGrid>
			</PageGridItem>
			{(isMobile && noResearchPublished) ? "" : <PageGridItem col={width > 1300 ? "7 / span 6" : "1 / span 12"} className="company-page-ticker-research-card">
				<PageContentBodyGrid style={{ height: "100%", gridAutoRows: "auto" }} rowGap="xl" gridColCount="6" subGrid>
					{/* Research Details */}
					<TickerResearchCard
						compact={compactView}
						ticker={{ targetPrice, currentRating }}
						analysts={coveringAnalysts}
						editMode={editMode}
						extraInfo={extraInfo}
					/>
				</PageContentBodyGrid>
			</PageGridItem>}
			{/* No research message */}
			{!compactView && !isMobile && !extraInfoLoading && <ResearchSummary summary={extraInfo} loading={extraInfoLoading} />}
		</>
	);
}

export default CompanyHeaderCards;
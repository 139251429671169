import React, { useState, useEffect } from 'react';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import TickerSectorItem from '../../../components/misc/TickerSectorItem';
import DetailField from '../../../components/shared/detail-section/DetailField';
import useWindowDimensions, { formatDate, getImageFromS3, useStorage, useWatchList } from '../../../utils/Utils';
import SelectDetailField from '../../../components/shared/detail-section/SelectDetailField'
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import DetailCard from '../../../components/cards/DetailCard';
import ResearchSummary from '../../../components/shared/research/ResearchSummary';
import PageGridDivider from '../../../layout/grid/page/PageGridDivider';

const TickerPageDetailsCard = ({ editMode, ticker, compact, infoIconOnClick, hideWatchlistIcon, extraInfo, extraInfoLoading }) => {
	const [formattedDate, setFormattedDate] = useState("")
	const { me, handleWatchListClick } = useWatchList()
	const [imageUrl, setImageUrl] = useState("")
	const storage = useStorage();
	const { width } = useWindowDimensions();
	const isMobile = width < 768;


	useEffect(() => {
		if (ticker && ticker.added) {
			let splitDate = ticker.dateAdded.split("-")
			let newDate = formatDate(splitDate[0], splitDate[1], splitDate[2])
			setFormattedDate(newDate)

		}
		if (ticker) {
			let [tick, exch] = ticker.ric.split(".")
			getImageFromS3(exch, tick).then(setImageUrl)
		}
	}, [ticker])

	const watched = me && me.watched && me.watched.tickers && me.watched.tickers.indexOf(ticker.ric) >= 0;

	return (
		<DetailCard id="ticker-page-detail-card">
			<PageGridItem col="1 / span 12">
				<TickerSectorItem hideWatchlistIcon={hideWatchlistIcon} infoIcon infoIconOnClick={infoIconOnClick} compactView={compact} size="xl" onWatchlist={watched} label={ticker.name} extensionLabel={ticker.ric} image={imageUrl} onClick={(me) => handleWatchListClick(me)} assetId={ticker.ric} />
			</PageGridItem>
			{!compact && (
				<>
					<PageGridItem className="transition-fade-up-slow" col="1 / span 12">
						<FlexWrapper gap={isMobile ? "l" : "xl"} wrap>
							<DetailField label="Australasia" value={ticker.country} />
							<SelectDetailField editMode={editMode} label="Industry" value={ticker.gicIndustry} />
							<SelectDetailField editMode={editMode} label="Sector" value={ticker.gicSector} />
						</FlexWrapper>
					</PageGridItem>
					<PageGridItem className="transition-fade-up-slow" col="1 / span 12">
						<FlexWrapper gap={isMobile ? "l" : "xl"} wrap>
							<DetailField editMode={editMode} label="Headquarters Location" value={ticker.address + ", " + ticker.country} />
							<DetailField editMode={editMode} label="Website" value={ticker && ticker.website ? <a className="text-decoration-none" href={ticker.website} target="_blank">{ticker.website}</a> : "-"} />
						</FlexWrapper>
					</PageGridItem>
					{isMobile && <>
						<PageGridDivider />
						<PageGridItem col="1 / span 12">
							<ResearchSummary summary={extraInfo} loading={extraInfoLoading} />
						</PageGridItem></>}
				</>
			)}
		</DetailCard>
	);
}

export default TickerPageDetailsCard;
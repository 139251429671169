import React from 'react';
import { AnalystDetails } from '../../../components/cards/AnalystCard';
import Divider from '../../../components/misc/Divider';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import DetailField from '../../../components/shared/detail-section/DetailField';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import useWindowDimensions from '../../../utils/Utils';

const AnalystGeneralSection = ({ analyst }) => {
	const { width } = useWindowDimensions();
	const isMobile = width < 992;

	return (
		<FlexWrapper fullWidth gap={isMobile ? "l" : "xl"} wrap={isMobile}>
			{analyst && <AnalystDetails data={analyst} isMobile={isMobile} />}
			<Divider vertical={!isMobile} height={48} flexItem />
			<DetailField link={`mailto:${analyst && analyst.email && analyst.email}`} label="Email" value={analyst && analyst.email && analyst.email} />
			<DetailField link={`tel:${analyst && analyst.phone && analyst.phone}`} label="Phone" value={analyst && analyst.phone && analyst.phone} />
		</FlexWrapper>
	);
}

export default AnalystGeneralSection;
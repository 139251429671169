import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SideBarLeft from '../layout/sidebar/SideBarLeft';
import TopBar from '../layout/topbar/TopBar';
import MobileTopBar from '../layout/topbar/mobile/MobileTopBar';
import MobileMenu from '../layout/topbar/mobile/MobileMenu';
import MobileSearchOverlay from '../layout/topbar/mobile/MobileSearchOverlay';

const PortalWrapper = ({me}) => {
	const sideBarExtensionLeft = false;
	const sideBarRight = false;
	const sideBarExtensionRight = false;
	const alertBannerActive = false;
	let [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	let [searchOpen, setSearchOpen] = useState(false);
	const location = useLocation(); // Get the current location

	// Close the search overlay when a route change occurs
	useEffect(() => {
		setSearchOpen(false);
	}, [location]);
	
	return (
		<div
			id="master-grid"
			className={`${sideBarExtensionLeft ? " grid-sidebar-ext-left-active " : ""}${sideBarRight ? " grid-sidebar-right-active " : ""}${
				sideBarExtensionRight ? " grid-sidebar-ext-right-active " : ""
			}${alertBannerActive ? " grid-alert-banner-active " : ""}`}
			style={{position: searchOpen ? "fixed" : "", overflow: searchOpen ? "hidden" : ""}}
		>
			<TopBar me={me || {}} />
			<MobileTopBar menuClick={() => setMobileMenuOpen(true)} user={me} onSearchClick={() => setSearchOpen(!searchOpen)} searching={searchOpen} />
			{mobileMenuOpen && <MobileMenu user={me} onClick={() => setMobileMenuOpen(false)} />}
			{searchOpen && <MobileSearchOverlay searchOpen={searchOpen} closeSearch={() => setSearchOpen(false)} />}
			<SideBarLeft />
			<Outlet />
		</div>
	);
}

export default PortalWrapper;
import React from "react";
import "./Page.scss";
import "./PageContentBanner.scss";
import "./PageContentFilters.scss";
import useWindowDimensions from "../../../utils/Utils";
import ClassedElement from "../../../utils/ClassedElement";

const ClassedDiv = (className) => ClassedElement(className, "div");
const withContentInner = function (WrappedComponent) {
	return ({ children, ...otherProps }) => (
		<WrappedComponent {...otherProps}>
			{children}
		</WrappedComponent>
	);
};

const Page = ({fixedBanner, noBanner, id, showScrollbar, children, style}) => {
	return (
		<div id={id} className={`page-content-outer${fixedBanner ? " fixed-banner" : noBanner ? " no-banner" : ""}${showScrollbar ? "" : " hide-scrollbar"}`} style={{...style}}>
			{children}
		</div>
	)
}

export const PageContentBanner = (props) => {
	const { children, divider, justified, gap, wrap, direction, style, mobile } = props;
	return (
		<div className={`page-content-banner transition-fade-in${divider ? " page-content-banner-divider" : ""}${justified ? (" page-content-justified-" + justified) : ""}${gap ? " gap-" + gap : ""}${mobile ? " pb-l" : " pb-m"}`} style={{...style, flexWrap: wrap ? " wrap" : "", flexDirection: direction ? direction : ""}}>
			{children}
		</div>
	)
}

export const PageContentBodyGrid = ({ children, className, rowGap, columnGap, forwardRef, id, gridColCount, subGrid, splitScroll, divider, showScrollbar, style, paddingTop }) => {
	const { width } = useWindowDimensions();

	return (
		<div
			ref={forwardRef}
			id={id}
			className={`page-content-body-grid transition-fade-in-slow${divider ? " grid-divider-" + divider : ""}${className ? " " + className : ""}${
				rowGap ? " row-gap-" + rowGap : ""
			}${columnGap ? " column-gap-" + columnGap : ""}${splitScroll && width > 1200 ? " grid-split-scroll" : ""}${showScrollbar ? "" : " hide-scrollbar"}`}
			style={{
				...style,
				gridTemplateColumns: "repeat(" + gridColCount + ", minmax(0, 1fr))",
				paddingBottom: subGrid ? 0 : "",
				paddingTop: (subGrid && divider !== "top") ? 0 : paddingTop === "none" ? 0 : "",
				gridArea: subGrid ? "auto" : ""
			}}
		>
			{children}
		</div>
	);
}

export default Page;
export const PageContent = ClassedDiv("page-content");
export const PageContentInner = ClassedDiv("page-content-inner");
export const PageContentFilters = withContentInner(ClassedDiv("page-content-filters"));
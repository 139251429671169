import React from 'react';
import SlateEditor from './SlateEditor';

const SelectableText = ({styles, getValue, config, datasets, getStyle})  => {

	const selected = getValue(config.dataName);
	
	if (!selected || selected === '') {
		return null;
	}
	if (!datasets) {
		return null;
	}

	const datasetName = config.dataset.name;
	const datasetKey = config.dataset.key;

	const dataset = datasets.find(set=>set.name === datasetName);
	const options = dataset.options.map(option=>{return {label: option.label, value: option.values.find(val=>val.key === datasetKey).value}});

	const selectedLabel = options.find(opt => opt.label === selected);

	if (!selectedLabel) {
		return null;
	}

	const content = selectedLabel.value;
	if (!content || !content.length) {
		return null;
	}

	// const myStyles = Object.keys(config.styles).map(key => {
	// 	const actual = styles[config.styles[key]];
	// 	const converted = getStyle(actual);
	// 	converted.lineHeight = "normal";
	// 	const val = {};
	// 	val[key] = converted;
	// 	return val;
	// });

	// const flattenedStyles = {};
	// myStyles.forEach(style => Object.assign(flattenedStyles, style));

	return <div className="selectable-text-selected" >
		<SlateEditor load={JSON.parse(content)} styleDefs={config.styles} styles={styles} getStyle={getStyle} />
	</div>
}

export default SelectableText;

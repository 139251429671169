import React, { useEffect, useRef } from "react"
import { PageContentBodyGrid } from "../../../layout/grid/page/Page";
import PageGridItem from "../../../layout/grid/page/PageGridItem";
import BackToTop from "../../lists-and-tables/BackToTop";
import MiniResearchList from "../../lists-and-tables/MiniResearchList";
import LoadingIcon from "../../misc/LoadingIcon";

const ResearchTable = ({ publications, setSelectedResearch, morePublications, loadMore, compact, loadingPublications, forwardRef, grouping, hideImage, hideWatchlistIcon }) => {

	return (
		<>
			<PageGridItem col="1 / span 12">
				<PageContentBodyGrid rowGap="xl" gridColCount="1" divider={!compact && "top"} style={{ overflow: "visible", paddingTop: 0 }} >
						<div id="top" ref={forwardRef}>
							<MiniResearchList
								sticky
								suggestionText="Published research for this ticker will be listed here."
								data={publications}
								selectResearch={setSelectedResearch}
								grouping={grouping}
								hideImage={hideImage}
								hideWatchlistIcon={hideWatchlistIcon}
							/>
						</div>
					{!morePublications ? (publications.length < 6 ? '' : <BackToTop href="#top" />) : loadingPublications && <LoadingIcon centered size={16} />}
				</PageContentBodyGrid>
			</PageGridItem>
		</>
	);
}

export default ResearchTable;
import React, { useState } from "react";
import toValueLabelPair from "../../functions/toValueLabelPair";
import LoadingIcon from "../misc/LoadingIcon";
import SelectSearchField from "./SelectSearchField";
import TextFieldLabel from "./TextFieldLabel";
import "./TickerListSearchField.scss";

const TickerListSearchField = ({ tickerList, tickerListLoading, onChange, width, minWidth, value, label, ...otherProps }) => {

	const [inputHasValue, setInputHasValue] = useState(false)

	if (tickerListLoading) {
		return <LoadingIcon size={32} />;
	}
	return (
		<div className="ticker-search-wrapper">
			{label && <TextFieldLabel>{label}</TextFieldLabel>}
			<SelectSearchField
				minWidth={minWidth}
				width={width}
				value={toValueLabelPair(value)}
				options={tickerList && tickerList.map((ticker) => { return { value: ticker, label: ticker } })}
				onChange={(obj) => onChange(obj && obj.value)}
				onInputChange={(e) => {setInputHasValue(e && e.length > 0) }}
				menuIsOpen={!!inputHasValue}
				{...otherProps}
			/>
		</div>
	)
}

export default TickerListSearchField;

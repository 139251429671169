import React, { useEffect, useState } from 'react';
import { Search } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { AnalystDetails } from '../../../components/cards/AnalystCard';
import Card from '../../../components/cards/Card';
import AsyncSearchField from '../../../components/inputs/AsyncSearchField';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import TickerSectorItem from '../../../components/misc/TickerSectorItem';
import OverlayWrapper from '../../../components/popups/OverlayWrapper';
import NoResultsMessage from '../../../components/shared/prompts/NoResultsMessage';
import { analytics, getPortalSearchExtensionLabel, getPortalSearchOptions, selectPortalSearchOption, useStorage, watched, useWatchList, returnGroupingFromType, getPortalSearchLogo } from '../../../utils/Utils';
import "./MobileSearchOverlay.scss";
import { debounce } from 'throttle-debounce';

const MobileSearchOverlay = ({ searchOpen, closeSearch }) => {
	const storage = useStorage();
	const [analysts, setAnalysts] = useState([]);
	const [tickers, setTickers] = useState([])
	const [filteredAnalysts, setFilteredAnalysts] = useState([]);
	const [hasSearchTerm, setHasSearchTerm] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchText, setSearchText] = useState('')
	const [fetchingResults, setFetchingResults] = useState(false)

	const { track, page } = analytics;
	const { me, handleWatchListClick } = useWatchList()
	const navigate = useNavigate()

	useEffect(() => {
		setLoading(true)
		Promise.all([
			storage.getOrFetch("/portal/analysts"), 
			storage.getOrFetch("/crm/tickers")
		]).then(([analystResponse, tickersResponse]) => {
			setAnalysts(analystResponse)
			setFilteredAnalysts(analystResponse)
			setTickers(tickersResponse)
			setLoading(false)
		});
		page()
	}, [])

	const _loadSuggestions = (query, callback) => {
		getPortalSearchOptions(query, setFetchingResults, tickers).then(callback);
	}
	const loadSuggestions = debounce(500, _loadSuggestions);


	const handleInputChange = (inputValue, action) => {
		if (action.action !== "input-blur" && action.action !== "menu-close") {
		 setSearchText(inputValue)
		 return inputValue
		}  
		return searchText
	};

	return (
		<OverlayWrapper id="search-overlay">
			<FlexWrapper direction="column" align="stretch" wrap className="mobile-search-wrapper colour-bg-neutral-light">
				<div className="search-field-wrapper">
					<AsyncSearchField
						autoFocus
						outlined
						placeholder="Search"
						icon={<Search />}
						classNames={["mobile-search"]}
						onChange={(e) => selectPortalSearchOption(e, navigate)}
						loadOptions={loadSuggestions}
						isLoading={fetchingResults || loading}
						menuIsOpen={searchOpen}
						menuPortalTarget={document.getElementById("mobile-menu-target")}
						menuPosition="absolute"
						inputValue={searchText}
						onInputChange={handleInputChange}
						
						components={{
							Option: (props) => {
								let path =  props.data?.value?.groupingDetails?.urlSafeName && props.data?.value?.urlSafeName ? `/group/${props.data?.value?.groupingDetails?.urlSafeName}/${props.data?.value?.urlSafeName}` : null

								return (
									<Card
										style={{ cursor: "pointer" }}
										onClick={() => {
											props.innerProps.onClick(props.data)
											closeSearch()
										}}
									>
										{props.data.type === "analyst" ? (
											<AnalystDetails isMobile data={props.data.value} />
										) : (
											<TickerSectorItem
												grouping={returnGroupingFromType(props.data.type)}
												assetId={props.data.type === "ticker" ? props.data.value.ric : props.data.type === "sector" ? props.data.value.groupName : props.data.value.name}
												isMobile
												image={getPortalSearchLogo(props.data)}
												label={props.data.label}
												extensionLabel={getPortalSearchExtensionLabel(props.data)}
												onWatchlist={watched(me, props.data.type === "ticker" ? props.data.value.ric : props.data.type === "sector" ? props.data.value.groupName : props.data.value.name, path)}
												hideWatchlistIcon={props.data.type === "note"}
												onClick={(me) => {
													handleWatchListClick(me)
												}}
												path={path}
											/>
										)}
									</Card>
								)
							},
							NoOptionsMessage: () => searchText ? <NoResultsMessage noHeader={searchText.length < 3} noIcon subMessage={searchText.length < 3 ? <>Please enter 3 or more characters to see results.</> : null } /> : <NoResultsMessage noHeader subMessage={<>Try searching "AIA.NZ"</>} noIcon />,
						}}
					/>
				</div>
				<div id="mobile-menu-target">

				</div>
			</FlexWrapper>
		</OverlayWrapper>
	);
}

export default MobileSearchOverlay;
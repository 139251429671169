import React, { useEffect, useState } from 'react';
import { Bell, Shield, User } from 'react-feather';
import { debounce } from 'throttle-debounce';
import Button from '../../../components/buttons/Button';
import Checkbox from '../../../components/inputs/Checkbox';
import LoadingIcon from '../../../components/misc/LoadingIcon';
import DetailField from '../../../components/shared/detail-section/DetailField';
import DetailSectionHeader from '../../../components/shared/detail-section/DetailSectionHeader';
import FormAlertMessage from '../../../components/shared/prompts/FormAlertMessage';
import Page, { PageContentBodyGrid } from '../../../layout/grid/page/Page';
import PageGridDivider from '../../../layout/grid/page/PageGridDivider';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import LoginSubText from '../../../login/components/LoginSubText';
import useWindowDimensions, { useRemote } from '../../../utils/Utils';
import { analytics } from '../../../utils/Utils';
import { toastWarning, toastSuccess, toastDanger } from '../../../components/popups/Toasts';

const SettingsProfile = ({ user }) => {
	const { width } = useWindowDimensions();

	const [currentPassword, setCurrentPassword] = useState("")
	const [newPassword, setNewPassword] = useState("")
	const [confirmNewPassword, setConfirmNewPassword] = useState("")
	const [invalid, setInvalid] = useState("")

	const [strength, setStrength] = useState(null);
	const [editDetailsMode, setEditDetailsMode] = useState(null);
	const remote = useRemote();

	useEffect(() => { analytics.page() }, [])

	const xxl = width > 1400;
	const xl = width > 1140;
	const lg = width < 1140;



	const _checkStrength = (e) => {
		remote.post("/portal/password-strength", { newPassword: e.target.value }).then(r => {
			setStrength(r);
		});
	}

	const checkStrength = debounce(500, _checkStrength);

	const updatePassword = (e) => {
		if (!currentPassword) {
			setInvalid("Enter your current password.")
			return;
		}
		if (!newPassword || !confirmNewPassword) {
			setInvalid("Enter a new password and confirm it.")
			return;
		}
		if (newPassword !== confirmNewPassword) {
			setInvalid("Your new password does not match. Please check them and try again.")
			return;
		}
		if (strength.score < 3) {
			setInvalid("Password is too weak.")
			return;
		}
		remote.post("/portal/reset-password", { oldPassword: currentPassword, newPassword }).then(r => {
			if (r.success) {
				toastSuccess("Password successfully updated")
			} else {
				toastDanger("Failed to set password")
			}
		})
	}

	return (
		<Page noBanner style={{ paddingBottom: 40 }}>
			<PageContentBodyGrid rowGap="xl" splitScroll>
				{!user ? <PageGridItem col={"1 / span 12"}>
					<div style={{ height: "100vh", paddingTop: "30vh" }}>
						<LoadingIcon centered />
					</div>
				</PageGridItem>
					: <>{/* grid container for the user details section */}
						<PageGridItem col={xxl ? "1 / span 6" : lg ? "1 / span 12" : "1 / span 6"}>
							<PageContentBodyGrid rowGap="xl" gridColCount="8" subGrid divider={xl ? "right" : ""}>
								<PageGridItem col="1 / span 8">
									<DetailSectionHeader header="General Details" icon={<User />} />
								</PageGridItem>
								<PageGridItem col="1 / span 4">
									<DetailField disabled editMode value={user.firstName} label="First Name" />
								</PageGridItem>
								<PageGridItem col="5 / span 4">
									<DetailField disabled editMode value={user.lastName} label="Last Name" />
								</PageGridItem>
								<PageGridItem col="1 / span 4">
									<DetailField disabled editMode value={user.email} label="Email" />
								</PageGridItem>
								<PageGridItem col="5 / span 4">
									<DetailField disabled editMode value={user.phoneNumber} label="Mobile" />
								</PageGridItem>
								{/* <PageGridItem col="1 / span 4">
							<Checkbox disabled label="Two Factor Authentication" />
						</PageGridItem>
						<PageGridDivider col="1 / span 8"/>
						<PageGridItem col="1 / span 8">
							<DetailSectionHeader header="Notifications" icon={<Bell />} />
						</PageGridItem>
						<PageGridItem col="1 / span 4">
							<Checkbox disabled className="mr-m" label="Email" />
							<Checkbox disabled label="Portal" />
						</PageGridItem> */}
							</PageContentBodyGrid>
						</PageGridItem>
						<PageGridItem col={xxl ? "7 / span 4" : lg ? "1 / span 12" : "7 / span 6"}>
							<PageContentBodyGrid rowGap="xl" gridColCount="8" subGrid>
								{/* Security Page Details */}
								<PageGridItem col="1 / span 8">
									<DetailSectionHeader header="Password" icon={<Shield />} />
								</PageGridItem>
								<PageGridItem col="1 / span 8">
									{/* This input is here to confuse autofill and stop it from putting the username in the search box, do not remove */}
									<DetailField editMode type="password" label="Current Password" placeholder="********" onChange={(e) => { setCurrentPassword(e.target.value); setInvalid("") }} />
									<input type="username" style={{position: "absolute", zIndex: "-1", height: "1px", width: "1px", border: "none"}} tabIndex="-1" aria-hidden="true" autoComplete='username'/>
								</PageGridItem>
								<PageGridItem col="1 / span 4">
									<DetailField invalid={invalid} disabled={!currentPassword} editMode type="password" label="New Password" placeholder="********" onChange={(e) => { setNewPassword(e.target.value); checkStrength(e); setInvalid("") }} />
								</PageGridItem>
								<PageGridItem col="5 / span 4">
									<DetailField invalid={invalid} disabled={!currentPassword} editMode type="password" label="Confirm New Password" placeholder="********" onChange={(e) => { setConfirmNewPassword(e.target.value); setInvalid("") }} />
								</PageGridItem>
								<PageGridItem col="1 / span 4">
									<Button disabled={!(currentPassword && newPassword && confirmNewPassword)} onClick={updatePassword}>Update Password</Button>
								</PageGridItem>
								<PageGridItem alignItems="flex-start" col="1 / span 8">
									{invalid && <FormAlertMessage justify="flex-start" removeBottomMargin fullWidth type="danger" message={invalid} />}
									{strength && strength.feedback && strength.feedback.warning && <FormAlertMessage justify="flex-start" removeBottomMargin fullWidth type="warning" message={strength.feedback.warning} />}
									{strength && strength.feedback && strength.feedback.suggestions && strength.feedback.suggestions.length > 0 && <FormAlertMessage justify="flex-start" fullWidth type="warning" message={strength.feedback.suggestions[0]} />}
								</PageGridItem>
							</PageContentBodyGrid>
						</PageGridItem>
					</>}
			</PageContentBodyGrid>
		</Page>
	);
}

export default SettingsProfile;
import React from 'react';
import "./Divider.scss";

const Divider = (props) => {
	const { thick, spacing, spacingTop, spacingBottom, spacingLeft, spacingRight, noMargin, dashed, vertical, style, height } = props;
	let className = "divider";
	if (thick) {
		className += " divider-thick";
	}
	if (spacing) {
		className += " spacing-" + spacing;
	}
	if (spacingTop) {
		className += " spacing-top-" + spacingTop;
	}
	if (spacingBottom) {
		className += " spacing-bottom-" + spacingBottom;
	}
	if (spacingLeft) {
		className += " spacing-left-" + spacingLeft;
	}
	if (spacingRight) {
		className += " spacing-right-" + spacingRight;
	}
	if (dashed) {
		className += " divider-dashed";
	}
	if (vertical) {
		className += " divider-vertical";
	}
	return (
		<hr className={className} style={{ ...style, margin: noMargin ? 0 : "", height: vertical ? height : ""}} />
	)
}

export default Divider;
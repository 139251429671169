import React, { useEffect } from 'react';
import Page from '../../../layout/grid/page/Page';
import LibraryPageContentBanner from './LibraryPageContentBanner';
import LibraryTable from './LibraryTable';
import { analytics } from '../../../utils/Utils';
import { LibraryDocsWrapper } from './withLibraryDocs';


const LibraryPage = () => {

	const { page } = analytics;

	useEffect(() => {
		page()
	}, [])
	
	return (

		<Page id={'library-page'} fixedBanner>
			<LibraryDocsWrapper>
				<LibraryPageContentBanner />
				<LibraryTable hideImage={false}/>
			</LibraryDocsWrapper>
		</Page >
	);
}

export default LibraryPage;
import React, { useState, useEffect } from 'react';
import { expiringMemo } from '../../utils/Utils';
import placeholderImage from "../../resources/images/sectors/research-2.png";

const getDataUrl = (exchange, tickerCode) => {
	let countryCode = exchange

	if (exchange === 'NZX') {
		countryCode = 'NZ';
	} else if (exchange === 'ASX') {
		countryCode = 'AU'
	} else if (exchange === 'NYSE') {
		countryCode = 'US'
	}

	return fetch(`https://dja724jxx1.execute-api.ap-southeast-2.amazonaws.com/GenerateAssetSignedUrl?folder=${countryCode}&filename=${tickerCode}.png`).then(response => response.json()).catch(e => { });
}

const cachedUrl = expiringMemo(getDataUrl);

const TickerImage = ({ exchange, tickerCode, alt, size, showPlaceholder }) => {
	const [loading, setLoading] = useState(true)
	const [dataUrl, setDataUrl] = useState(null);

	if (!exchange && tickerCode && tickerCode.includes(".")) {
		exchange = tickerCode.split(".")[1];
		tickerCode = tickerCode.split(".")[0];
	}

	useEffect(() => {
		if (!exchange) {
			return;
		}

		cachedUrl(exchange, tickerCode).then(json => {
			if (json.url) {
				setDataUrl(json.url)
			} else {
			}
			setLoading(false)
		})
	}, [exchange, tickerCode]);
	if (loading) return null
	if (dataUrl) {return <img src={dataUrl} alt={alt ? alt : "Asset logo"} />}
	if (showPlaceholder) {
		return <img src={placeholderImage} alt="ticker/sector logo" />
	} else {
		return null;
	}
}

export default TickerImage;


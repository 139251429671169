import React from 'react';
import Tooltip from '../popups/Tooltip';
import "./ProfileImage.scss";

const ProfileImage = (props) => {
	const { src, altTag, focused, initials, noHover, size, user, name, tooltip, style } = props;
	
	return (
		<Tooltip label={tooltip} disabled={!tooltip}>
			<div
				className={`profile-image-container${focused ? " profile-image-focused" : ""}${!src || initials ? " profile-initials" : ""}${noHover ? " profile-image-no-hover" : ""}`}
				style={{ ...style, width: size, height: size }}
			>
				{src ? (
					<img src={"data:image/png;base64, "+src} alt={altTag ? altTag : "profile-image"} style={{ width: size, height: "auto" }} />
				) : (
					<div className="profile-image-text">
						{name ? name.substring(0, 1).toUpperCase() : user ? (user.name ? user.name.substring(0, 1).toUpperCase() : user.firstName && user.firstName.substring(0, 1).toUpperCase()) : initials}
					</div>
				)}
			</div>
		</Tooltip>
	);
}

export default ProfileImage;
import React, { forwardRef, useEffect, useState } from 'react';
import { ExternalLink } from 'react-feather';
import TickerSectorImage from '../misc/TickerSectorImage';
import Divider from '../misc/Divider';
import FlexWrapper from '../misc/FlexWrapper';
import Card from './Card';
import "./ResearchCard.scss";
import XlsIcon from '../icons/XlsIcon';
import WatchlistIcon from '../icons/WatchlistIcon';
import TableActionIcon from '../lists-and-tables/TableActionIcon';
import useWindowDimensions, { analytics, formatDate, getImage, onDocumentClick, downloadDocuments, useStorage, watched, useWatchList } from '../../utils/Utils';

const ResearchCard = forwardRef(({ research, hideDownloadIcons, onClick, unread, grouping, hideImage, hideWatchlistIcon }, ref) => {

	const { title, date, publishedDate, ticker, savedData, imageUrl, coveredShortName, summary, path } = research;
	const [tickers, setTickers] = useState([]);
	const storage = useStorage();
	const { me, handleWatchListClick } = useWatchList()
	const { width } = useWindowDimensions();
	const isMobile = width < 1000;

	const datafiles = research && research.models ? research.models : [] ;
	const defaultPdf = (research && research.files && research.files.filter(f => f.defaultPdf)) || [];

	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers);
	}, []);

	return (
		<Card className={`research-card${unread ? " unread-research" : ""}`} onClick={() => onClick && onClick(research)} ref={ref}>
			<FlexWrapper wrap gap="m">
				<FlexWrapper gap="s" fullWidth justify="space-between" align="flex-start">
					<div style={{ width: "calc(100% - 48px)" }}>
						<div className="research-title">{title}</div>
						{summary && <div className="research-preview">{summary}</div>}
					</div>
					{!hideImage && <div className="research-ticker-logo"><TickerSectorImage image={imageUrl} /></div>}
				</FlexWrapper>
				<FlexWrapper fullWidth className="research-details" gap="s">
					<div className="research-published-date">{(publishedDate || date) ? formatDate(publishedDate ? publishedDate : date, "dd/MM/yyyy") : "-"}</div>
					{coveredShortName && (
						<>
							<Divider vertical height={10} />
							<div className="research-ticker">{coveredShortName}</div>
						</>
					)}
					{!hideDownloadIcons && (
						<>
							<Divider vertical height={10} />
							<TableActionIcon noMargin icon={<ExternalLink size={16} />} onClick={(e) => { analytics.track("downloadPDF", { asset: coveredShortName, publication: research }); downloadDocuments(e, defaultPdf) }} />
							{!(isMobile && datafiles.length < 1 ) &&
								<>
									<Divider vertical height={10} />
									<TableActionIcon noMargin icon={<XlsIcon size={16} />} onClick={(e) => { analytics.track("downloadData", { asset: coveredShortName, publication: research }); downloadDocuments(e, datafiles, true) }} />
								</>
							}
						</>
					)}
					{!hideWatchlistIcon && (
						<>
							<Divider vertical height={10} />
							<WatchlistIcon
								style={{ transform: "translateY(1px)" }}
								onWatchList={watched(me, coveredShortName, path)}
								size={16}
								assetId={coveredShortName}
								onClick={(me) => handleWatchListClick(me)}
								grouping={grouping ? grouping : "tickers"}
								path={path}
							/>
						</>
					)}
				</FlexWrapper>
			</FlexWrapper>
		</Card>
	);
})

export default ResearchCard;


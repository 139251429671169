import React from 'react'
import "./Disclaimer.scss";
import logo from './craigs-sidebar-logo.png';
import nzxLogo from './nzx-footer.png';

const DisclaimerTextWrapper = ({ header, text, children }) => {
	return (
		<div className="disclaimer-text-wrapper">
			<h3>{header}</h3>
			<p>{text || children}</p>
		</div>
	)
}

const Disclaimer = (props) => {
	return (
		<>
			{/* PAGE 1 */}
			<div id="d-page-1" className="research-viewer-page p-none" style={{ height: "836pt", width: "595pt" }}>
				<div className="disclaimer-page">
					<div className="disclaimer-sidebar">
						{/* Logo */}
						<div className="logo-wrapper" style={{ width: 100 }}>
							<img src={logo} alt="craigs ip" />
						</div>
						{/* Footer */}
						<div className="sidebar-footer">
							<p>craigsip.com</p>
							<img src={nzxLogo} alt="craigs ip" style={{ width: 50 }} />
						</div>
					</div>
					<div className="disclaimer-content">
						<h2>CIP Research Disclaimer</h2>
						<DisclaimerTextWrapper header="Wholesale investors">
							This research is intended only for “wholesale clients” within the meaning of the New Zealand Financial Markets Conduct Act 2013 and the Australian
							Corporations Act 2001 respectively. This research is only intended to be distributed within New Zealand and Australia.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="Disclaimer and additional information">
							CIP research is general advice prepared by the Institutional Research Team at Craigs Investment Partners Limited (FSP 29641: ARBN 143 656 437: AFSL
							363407) (“CIP Research”). CIP Research documents are private communications to clients of Craigs Investment Partners Limited in New Zealand and
							Australia and is not intended for distribution to any person outside New Zealand and Australia except in accordance with all legal requirements of the
							relevant jurisdiction. CIP Research is not to be disclosed in whole or part or used by any other party without CIP’s prior written consent.
							<br /><br />
							All CIP Research is intended to be read in conjunction with the <a target="_blank" href="https://craigsip.com/corporate/institutional-equities/research-overview">CIP Research Overview</a>, which contains further information about our research department,
							policies, and methodologies, and the <a target="_blank" href="https://craigsip.com/coverage-conflicts">CIP Research disclosures</a> which contain more information about CIP’s broader relationship with the companies that it
							researches as well as any additional disclosures that you should be aware of when reading this document.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="General Advice only">
							Any advice contained within CIP Research is general advice only and has been prepared by CIP without taking into account any person’s objectives,
							financial situation or needs. Any person, before acting on any advice contained within CIP Research, should first consider consulting with a financial
							adviser to assess whether that advice is appropriate for their objectives, financial situation and needs. Those acting upon such information without
							advice do so entirely at their own risk.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="Reliance">
							Though the information contained within CIP Research is believed to be reliable and has been obtained from public sources believed to be reliable, CIP
							makes no representation as to its accuracy or completeness. To the fullest extent permitted by law CIP, its affiliates and their respective officers,
							directors, employees or agents, disclaim any and all liabilities for any loss or damage howsoever arising in connection with the use of this
							communication. <br /><br />
							Any projections contained within CIP Research are indicative estimates only. Such projections are contingent upon matters outside the control of CIP
							(including but not limited to economic conditions, market volatility and company specific fundamentals) and therefore may not be realised in the future.
							It is important to note that while predictions are necessarily influenced by existing data and past events, past performance is not a reliable
							indication of future performance. <br /><br />
							Target prices are inherently imprecise and a product of the analyst’s judgment. The financial products discussed in CIP Research documents may not be
							suitable for all investors, and investors must make their own informed investment decisions. Prices and availability of financial products are subject
							to change without notice, and investment transactions can lead to losses as a result of price fluctuations and other factors.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="Coverage">
							Decisions to initiate or discontinue research coverage of a Company are made in accordance with the factors set out in the <a target="_blank" href="https://craigsip.com/corporate/institutional-equities/research-overview">CIP Research Overview</a>.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="Currency">
							The recommendations made in CIP Research reports are current as of the publication date. If you are reading a report more than 4 weeks after
							publication, it is likely that circumstances will have changed and at least some aspects of the analysis may no longer hold. Recommendations contained
							in one type of communication may differ from recommendations in others, whether as a result of differing time horizons, methodologies, perspectives or
							otherwise
						</DisclaimerTextWrapper>
						<div className="content-footer">
							<strong>CRAIGS INVESTMENT PARTNERS</strong> Introduction to Craigs Institutional Research 04.20 <strong>/ 01</strong>
						</div>
					</div>
				</div>
			</div>
			{/* PAGE 2 */}
			<div id="d-page-2" className="research-viewer-page p-none" style={{ height: "836pt", width: "595pt" }}>
				<div className="disclaimer-page">
					<div className="disclaimer-sidebar">
						{/* Footer */}
						<div className="sidebar-footer">
							<p>02 \</p>
						</div>
					</div>
					<div className="disclaimer-content">
						<DisclaimerTextWrapper header="Not an offer">
							CIP Research never constitutes an offer or invitation to purchase any financial product and should not be relied upon in connection with any contract or
							commitment whatsoever.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="Relationship with the Company">
							CIP always maintains the independence of its investment research, however given the breadth of its service offering and client base it is possible that
							it may have a broader business relationship with companies (”Companies”) that it advises on in CIP Research reports.
							<br /><br />
							CIP and its associates may have received and may continue to receive fees from a Company in relation to corporate advisory, underwriting or other
							professional investment services. If this is the case, this is detailed in <a target="_blank" href="https://craigsip.com/coverage-conflicts">CIP Research disclosures</a>.
							<br /><br />
							Neither CIP nor its research analysts received any direct financial or non-financial benefits from the Companies in exchange for the production of CIP
							research. However, CIP’s research analysts may receive assistance from the Companies in preparing their research which may include attending site visits
							and/or meetings hosted by the relevant Company. In some instances the costs of such site visits or meetings may be met in part or in whole by the
							Company, provided that CIP considers this reasonable given the specific circumstances relating to the site visit or meeting.
							<br /><br />
							Further, in preparing this report, the primary analyst or an individual who assisted in the preparation of A CIP Research report has likely been in
							contact with the relevant Company for confirmation/clarification of data, facts and statements, or to obtain permission to use company-sourced material
							in the report.
							<br /><br />
							CIP and its affiliates may trade financial products in the Companies as principal or as agent for another person, but always strictly observes insider
							trading restrictions and all other laws and regulations. CIP may consider this report in deciding to trade as principal. CIP may also engage in
							transactions for its own account or with clients in a manner inconsistent with the views taken in this communication. Others within CIP, including
							strategists, sales staff and other analysts, any take views that are inconsistent with those taken in this communication.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="Material holdings and interests">
							CIP, its subsidiaries, and/or its employees may hold interests in the Companies. To the extent that these interests are significant, they are detailed
							in <a target="_blank" href="https://craigsip.com/coverage-conflicts">CIP Research disclosures</a>.
							<br />
							CIP restricts research analysts from trading in securities for which they write research.
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="CIP contact">
							For more information please email: <a href="mailto:research.institutional@craigsip.com">research.institutional@craigsip.com</a>
						</DisclaimerTextWrapper>
						<DisclaimerTextWrapper header="More information about CIP research">
							For more information on the CIP research service including the research recommendation structure please see our <a target="_blank" href="https://craigsip.com/corporate/institutional-equities/research-overview">Research Overview</a>.
						</DisclaimerTextWrapper>
						<div className="content-footer">
							This communication may not be reproduced, distributed or published without CIPs prior written consent. Copyright © 2020 CIP.
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Disclaimer;
import React, { useEffect, useRef, useState } from 'react';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/inputs/TextInput';
import LoginMessageWrapper from '../components/LoginMessageWrapper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoginTextButton from '../components/LoginTextButton';
import LoadingIcon from '../../components/misc/LoadingIcon';
import { analytics, useRemote } from '../../utils/Utils';
import FormAlertMessage from '../../components/shared/prompts/FormAlertMessage';

const SetPasswordPage = ({ header }) => {
	const [password, setPassword] = useState("")
	const [confirmedPassword, setConfirmedPassword] = useState("")
	const [code, setCode] = useState("");
	const [validating, setValidating] = useState(true);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [invalidPassword, setInvalidPassword] = useState(false);
	const [reason, setReason] = useState("")
	
	const [search, setSearch] = useSearchParams();
	const navigate = useNavigate()
	const remote = useRemote();
	const confirmPasswordInputRef = useRef()

	useEffect(() => { analytics.page() }, [])

	useEffect(() => {
		setError(false);
		const code = search.get('u')
		if (code) {
			setCode(code);
			setSearch("");
			remote.post("/um/validate-password-reset", { code: code }).then(resp => {
				if (resp.success === true) {
					setValidating(false);
					setError(false);
				} else {
					setValidating(false);
					setError(true);
				}
			})
		} else {
			setValidating(false);
			setError(true);
		}
	}, [search])

	const handleSubmitPassword = () => {
		//TODO handle password restrictions checking.
		let isInvalid = validate()
		setInvalidPassword(isInvalid)
		if (!isInvalid) {
			setValidating(true);
			setInvalidPassword(false);
			remote.post("/um/update-password", { code: code, password: password }).then(resp => {
				setValidating(false);
				if (resp.success === true) {
					setSuccess(true);
				} else {
					setInvalidPassword(resp.errorMessage);
				}
			})
		}
	}

	const handleTextInput = (value, field) => {
		setInvalidPassword(false)
		switch (field) {
			case "password":
				setPassword(value)
				break;
			case "confirmPassword":
				setConfirmedPassword(value)
				break;
			default:
				break;
		}
	}

	const onKeyPress = (e, focusedInput) => {
		switch (focusedInput) {
			case "password":
				if (e.key === 'Enter') {
					e.preventDefault();
					confirmPasswordInputRef.current.focus()
				}
				break;
			case "confirmPassword":
				if (e.key === 'Enter') {
					e.preventDefault();
					handleSubmitPassword()
				}
				break;
			default:
				break;
		}
	}

	const validate = () => {
		if (password.length < 8) {
			return "Password must be at least 8 characters";
		}
		let count = password.match(/[a-z]/) ? 1 : 0;
		count += password.match(/[A-Z]/) ? 1 : 0;
		count += password.match(/[0-9]/) ? 1 : 0;
		count += password.match(/[^A-Za-z0-9]/) ? 1 : 0;
		if (count < 3) {
			return "Password must contain at least three of lower case, upper case, numeric and non-alphanumeric characters";
		}
		if (password !== confirmedPassword) {
			return "Passwords do not match";
		}
		return false;
	}

	if (validating) {
		return <div id="set-pw-page"><LoadingIcon centered={true} /></div>
	}

	if (error) {
		return <div id="set-pw-page"><LoginMessageWrapper header={header} message="Invalid code, perhaps it has expired?" /></div>
	}

	if (success) {
		return <div id="set-pw-page">
			<LoginMessageWrapper header={header} message="Success, your password has been updated. Please return to the login page to login." />
			<Button large fullWidth onClick={() => navigate("/")}><div className="font-weight-semi-bold">Login Page</div></Button>
		</div>
	}

	return (
		<div id="set-pw-page">
			<LoginMessageWrapper
				header={header}
				message="Please set a password for your account. Password must contain at least three of lower case, upper case, numeric and non-alphanumeric characters."
			/>
			{/* Fields */}
			<TextInput
				invalid={invalidPassword}
				className="mb-l"
				label="New Password"
				placeholder="New Password"
				type="password"
				onKeyDown={(e) => onKeyPress(e, "password")}
				onChange={(e) => handleTextInput(e.target.value, "password")}
			/>
			<TextInput
				forwardRef={confirmPasswordInputRef}
				invalid={invalidPassword}
				className="mb-l"
				label="Confirm Password"
				placeholder="Confirm Password"
				type="password"
				onKeyDown={(e) => onKeyPress(e, "confirmPassword")}
				onChange={(e) => handleTextInput(e.target.value, "confirmPassword")}
			/>
			{invalidPassword && <FormAlertMessage inlineIcon fullWidth type="danger" message={invalidPassword} />}
			<Button large fullWidth className="mb-xl" onClick={() => handleSubmitPassword() }>
				<div className="font-weight-semi-bold">
					Set Password
				</div>
			</Button>
			<LoginTextButton onClick={() => navigate('/')}>Back to login page</LoginTextButton>
		</div>
	);
}

export default SetPasswordPage;
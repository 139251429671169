import React, { useEffect } from 'react';
import { watched } from '../../utils/Utils';
import WatchlistIcon from '../icons/WatchlistIcon';
import Tooltip from '../popups/Tooltip';
import DetailField from '../shared/detail-section/DetailField';
import Divider from './Divider';
import FlexWrapper from './FlexWrapper';
import TickerSectorImage from './TickerSectorImage';
import "./TickerCompactIcon.scss";
import { useNavigate } from 'react-router-dom';

const TickerDetails = ({ ticker, prices, target, me, setMe, grouping }) => {
	let recommendation = prices && prices.find((val) => val.field === "Recommendation")
	let priceTarget = prices && prices.find((val) => val.field === "Target Price")
	let priceTargetFormatted = priceTarget && priceTarget.stringValue ? parseFloat(priceTarget.stringValue).toFixed(2) : "-"
	const price = target && target.price && target.price.price && parseFloat(target.price.price).toFixed(2) || "-";
	const priceAtLabel = target && target.price && target.price.dateTime || "";

	return (
		<div className="ticker-details">
			{/* Ticker details for tooltip popup */}
			<FlexWrapper justify="space-between" gap="m">
				<div className='ticker-details-names'>
					{/* code */}
					<h4>{ticker.ric}</h4>
					<Divider vertical height={16} />
					{/* name */}
					<h4>{ticker.name}</h4>
				</div>

				{/* watchlistIcon */}
				{/* <WatchlistIcon style={{ transform: "translateY(-8px)" }} onWatchList={watched(me, ticker.ric)} size={16} assetId={ticker} onClick={() => setMe(ticker)} grouping={grouping} /> */}
			</FlexWrapper>
			<FlexWrapper justify="space-between" gap="m" >
				{/* current Rating */}
				<DetailField label="Current Rating" value={recommendation && recommendation.stringValue ? recommendation.stringValue : "-"} valueClassName="font-size-header-4" />
				{/* Price At */}
				<DetailField label={`Price (Delayed)`} value={price} valueClassName="font-size-header-4" />
				{/* Price Target */}
				<DetailField label="Target Price" value={priceTargetFormatted} valueClassName="font-size-header-4" />
			</FlexWrapper>
		</div>
	);
}


const TickerCompactIcon = ({ ticker, prices, target, me, setMe, grouping }) => {
	const navigate = useNavigate();

	return (
		<Tooltip label={<TickerDetails ticker={ticker} prices={prices} target={target} me={me} setMe={setMe} grouping={ grouping ? grouping : "tickers"} />}>
			<TickerSectorImage image={ticker.logo} onClick={() => ticker.ric && navigate(`/company/${ticker.ric}`) } />
		</Tooltip>
	);
}

export default TickerCompactIcon;
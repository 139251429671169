import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./SidebarExtensionLink.scss";

const SidebarExtensionLink = (props) => {
	const { children, active, link, onClick, icon } = props;
	const navigate = useNavigate();
	return (
		<div onClick={onClick ? onClick : () => navigate(link)} className={`sidebar-extension-link${active ? " sidebar-extension-link-active" : ""}`}>
			{icon && <div className="icon-counter-wrapper">{icon}</div>}{children}
		</div>
	);
};

export default SidebarExtensionLink;
import React, { Component } from "react";
import LogoSrc from "../../resources/images/craigs-logo-white.png";
import LogoSrcBlack from "../../resources/images/craigs-logo.png";

class Logo extends Component {
	render() {
		const { className, id, onClick, width, black} = this.props;
		const classNames = ["logo", className].filter((cn) => !!cn).join(" ");
		return <img src={black ? LogoSrcBlack : LogoSrc} alt="logo" id={id} className={classNames} onClick={onClick} style={{width: width ? width : 115}} />;
	}
}

export default Logo;
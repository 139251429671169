import React from 'react';
import { getStyle } from './DataController';

const TitledField = ({styles, title, value, config})  => {

	const titleStyle = getStyle(styles[config.titleStyle]);
	const valueStyle = getStyle(styles[config.valueStyle]);
	if (config.width) {
		titleStyle.width = config.width + "pt";
		valueStyle.width = config.width + "pt";
	}

	return <div>
			<div style={titleStyle}>{title}</div>
			<div style={valueStyle}>{value}</div>
		</div>
	}

export default TitledField;
import React, { Component } from "react";
import { Search } from "react-feather";
import AsyncField from "./AsyncField";
import "./SelectSearchField.scss";
import "./SearchField.scss";
import "./AsyncSearchField.scss";

const AsyncSearchField = (props) => {
	return (
		<div className="async-search-field search-field-with-icon mr-l"  style={{width: props.fullWidth ? "100%" : ""}}><Search className="search-icon" /><AsyncField icon {...props} /></div>
	);
}

export default AsyncSearchField;

import React from 'react';
import { BookOpen, CheckCircle } from 'react-feather';
import Button from '../../buttons/Button';
import EmptyStateMessage from './EmptyStateMessage';
import { analytics, useRemote } from '../../../utils/Utils';


const NoResearchMessage = ({ onClick, onWatchlist, assetId, noWatchList, sector }) => {

	const remote = useRemote();

	const handleWatch = () => {
		switch (onWatchlist) {
			case true:
				analytics.track("removeWatchList", { ticker: assetId })
				remote.put(`/portal/watch/${assetId}/tickers`, { watch: false }).then(onClick)
				break;
			case false:
				analytics.track("addWatchList", { ticker: assetId })
				remote.put(`/portal/watch/${assetId}/tickers`, { watch: true }).then(onClick)
				break;

			default:
				break;
		}
	}

	if (sector) {
		return <EmptyStateMessage icon={<BookOpen className="transition-fade-in" />} header="No Sector Notes available" />
	}

	if (noWatchList) {
		return (
			<EmptyStateMessage
				icon={<BookOpen className="transition-fade-in" />}
				header={`We are currently not covering this ${sector ? "sector" : "company"}`}
			/>
		)
	}

	return (
		<EmptyStateMessage
			icon={<BookOpen className="transition-fade-in" />}
			header={`We are currently not covering this ${sector ? "sector" : "company"}`}
			subHeader={onWatchlist ? "We’ll let you know as soon as we are" : "If you’re interested please add it to your watchlist so we can track interest"}
		>
			<Button onClick={() => handleWatch()} variant={onWatchlist ? "positive-dark" : null} icon={onWatchlist ? <CheckCircle className="transition-fade-up-slow" /> : ""}>
				{onWatchlist ? "On Watchlist" : "Add To Watchlist"}
			</Button>
		</EmptyStateMessage>
	);
}

export default NoResearchMessage;
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Page, { PageContentBodyGrid } from "../../../layout/grid/page/Page";
import PageGridItem from "../../../layout/grid/page/PageGridItem";
import useWindowDimensions, { useRemote, analytics, useStorage, useInfiniteScroll, downloadDocuments } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import ResearchOverlay from "../../../components/misc/ResearchOverlay";
import ResearchViewer from "../../../components/shared/research/ResearchViewer";
import ResearchTable from "../../../components/shared/research/ResearchTable";
import SectorResearchCard from "./SectorResearchCard";
import SectorPageDetailsCard from "./SectorPageDetailsCard";
import NoResearchMessage from "../../../components/shared/prompts/NoResearchMessage";

const SectorPage = () => {

	const [sector, setSector] = useState({});
	const [companies, setCompanies] = useState([]);
	const [publications, setPublications] = useState([]);
	const [loadingPublications, setLoadingPublications] = useState(false);
	const [morePublications, setMorePublications] = useState(true);
	const [fullscreenResearch, setFullScreenResearch] = useState(false);
	const [selectedResearch, setSelectedResearch] = useState(null)
	const [analysts, setAnalysts] = useState([]);

	const params = useParams();
	const group = params.group;
	const grouping = params.grouping;
	const remote = useRemote();
	const { width } = useWindowDimensions()
	const navigate = useNavigate()
	const storage = useStorage();
	const scrollableElementChildRef = useRef()
	const scrollableElementRef = useRef()

	useEffect(() => {
		storage.getOrFetch('/portal/analysts').then(setAnalysts);
		storage.getOrFetch(`/portal/group/${grouping}/${group}/information`).then(res => {
			setSector({
				label: res.ticker,
				coveringAnalysts: res.coveringAnalysts,
				companies: res.tickers.map(d => d.ric),
				region: res.region,
				logo: res.logo,
				path: res.path,
				watch: res.watch
			})
			analytics.page()
			loadMorePublications(true, res.ticker)
		}).catch(() => navigate("page-not-found"))
	}, [group])

	useEffect(() => {
		if (!params.publication) {
			setSelectedResearch(null)
		} else if (publications && !selectedResearch) {
			let publication = publications.find(doc => doc && doc.did && doc.did.toString() === params.publication)
			if (publication) { setSelectedResearch(publication) } else { setSelectedResearch({ publicationId: params.publication }) }
		}
	}, [params])

	useEffect(() => {
		let list = []
		sector && sector.companies && sector.companies.forEach(ticker => {
			list.push(remote.get("/portal/ticker/" + ticker))
		});
		Promise.all(list).then((response) => {
			const respList = response.map((obj) => obj.ticker)
			setCompanies(respList)
		})
	}, [sector])

	useEffect(() => {
		if (sector && sector.label) {
			selectedResearch
				? document.title = selectedResearch.title ? sector.label + " | " + selectedResearch.title : sector.label + " | Craigs Investment Partners"
				: document.title = sector.label + " | Craigs Investment Partners"
		}
	}, [sector, selectedResearch])

	useEffect(() => {
		return () => document.title = "Client Portal | Craigs Investment Partners"
	}, [])

	const loadMorePublications = async (initialLoad, groupName) => {
		if ((loadingPublications || !morePublications) && !initialLoad) {
			return;
		}
		setLoadingPublications(true);
		// remote.get("/portal/group/" + group + "/publications?num=" + publications.length).then(p => {
		await remote.get(`/portal/library?num=50&offset=${initialLoad ? "0" : publications.length}&ticker=${encodeURIComponent((groupName ? groupName : group))}`).then(p => {
			const pubList = p.map((pub) => {
				return {
					analystName: pub.analystName,
					publicationId: pub.publicationId,
					publishedDate: pub.publishedDate,
					templateName: pub.template,
					ticker: pub.coveredShortName,
					title: pub.title,
					files: pub.files,
					read: pub.read,
					models: pub.models

				}
			})
			if (initialLoad) {
				setPublications(pubList);
			} else {
				setPublications(existing => existing.concat(pubList));
			}
			setMorePublications(p.length === 10);
			setLoadingPublications(false);

			if (params.publication && initialLoad) {
				let publication = p.find(doc => doc.publicationId.toString() === params.publication)
				if (publication) { setSelectedResearch(publication) } else { setSelectedResearch({ publicationId: params.publication }) }
				analytics.track("viewResearch", {
					id: publication.publicationId, ticker: publication.coveredShortName
				})
			}
		}).catch(() => {
			console.error("/portal/group/" + group + "/publications?num=" + publications.length, "failed")
			loadingPublications(false)
		})
	}

	const refreshCurrentList = () => {
		setLoadingPublications(true)
		remote.get("/portal/group/" + group + "/publications?skip=" + publications.length).then(p => {
			const pubList = p.map((pub) => {
				return {
					analystName: pub.analystName,
					publicationId: pub.publicationId,
					publishedDate: pub.publishedDate,
					templateName: pub.template,
					ticker: pub.coveredShortName,
					title: pub.title,
					files: pub.files,
					read: pub.read,
					models: pub.models
				}
			})
			setPublications(pubList);
			setLoadingPublications(false);
		})
	}
	const handleResearchSelect = (publication) => {

		var ua = navigator.userAgent.toLowerCase();
		var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
		const defaultPdf = (publication && publication.files && publication.files.filter(f => f.defaultPdf)) || []

		if (isAndroid && publication && !publication.hasHtmlVersion && publication.hasHtmlVersion !== undefined) {
			analytics.track("downloadPDF", { asset: publication.ticker, publication });
			downloadDocuments(null, defaultPdf)
			return;
		}

		setSelectedResearch(publication)
		if (publication) {
			analytics.track("viewResearch", { id: publication.publicationId, ticker: sector.label })
			navigate(`/group/${grouping}/${group}/${publication.publicationId}`);
		} else {
			analytics.track("viewResearchList", { ticker: sector.label })
			navigate(`/group/${grouping}/${group}`);
		}
	}

	const coveringAnalysts = analysts && sector && sector.coveringAnalysts && (sector.coveringAnalysts
		.map(a => {
			return analysts.find(s => s.name === a);
		}) || [])
		.sort((a, b) => {
			if (!a && !b) return 0
			if (!a) return -1
			if (!b) return 1

			return a.sortOrder - b.sortOrder
		});

	const [,] = useInfiniteScroll(loadMorePublications, scrollableElementChildRef, scrollableElementRef, true);
	const noResearchPublished = !loadingPublications && publications.length === 0;
	const isMobile = width < 768;

	return (
		<Page noBanner>
			{fullscreenResearch && <ResearchOverlay ticker={sector} setFullScreenResearch={setFullScreenResearch} publication={selectedResearch} analysts={analysts} companies={companies} />}
			{/* Page content */}
			<PageContentBodyGrid className={'padding-control'} style={{ paddingBottom: "0px" }} id="sector-page" rowGap="m" gridTemplateRows="1fr" forwardRef={scrollableElementRef}>
				{/* grid container for the sector details section */}
				<PageGridItem col={width > 1300 ? "1 / span 6" : "1 / span 12"} className="sector-page-ticker-details-card">
					<PageContentBodyGrid style={{ height: "100%", gridAutoRows: "auto" }} rowGap="xl" gridColCount="6" subGrid>
						{/* General Details */}
						<SectorPageDetailsCard sector={sector}  />
					</PageContentBodyGrid>
				</PageGridItem>
				{(isMobile && noResearchPublished)
					? ""
					: <PageGridItem col={width > 1300 ? "7 / span 6" : "1 / span 12"} className="sector-page-ticker-research-card" >
						<PageContentBodyGrid style={{ height: "100%", gridAutoRows: "auto" }} rowGap="xl" gridColCount="6" subGrid>
							{/* Research Details */}
							<SectorResearchCard analysts={coveringAnalysts} companies={companies} />
						</PageContentBodyGrid>
					</PageGridItem>}
				{/* displays research of sector and published list  */}
				{noResearchPublished
					? <PageGridItem fullWidth>
						<NoResearchMessage noWatchList sector />
					</PageGridItem>
					: selectedResearch ? (
						<ResearchViewer
							refreshCurrentList={refreshCurrentList}
							analysts={analysts}
							compact
							publication={selectedResearch}
							setSelectedResearch={handleResearchSelect}
							setFullScreenResearch={setFullScreenResearch}
						/>
					) : (
						<ResearchTable
							compact
							publications={publications}
							setSelectedResearch={handleResearchSelect}
							morePublications={morePublications}
							loadMore={loadMorePublications}
							loadingPublications={loadingPublications}
							forwardRef={scrollableElementChildRef}
							grouping="sectors"
							hideImage={true}
							hideWatchlistIcon
						/>
					)}
			</PageContentBodyGrid>
		</Page>
	);
}

export default SectorPage


import React from 'react';

class TableImpl extends React.Component {

	render() {
		const { config, styles, header, footer, otherRowStyle, getValue, getStyle } = this.props;

		const baseStyle = getStyle(styles[config.defaultCellStyle], true);
		const tableWidth = config.columnWidths.reduce((p,t)=>p+t);
		let { headerRows, /*headerColumns,*/ rowStyles, cellStyles, columnStyles, tableStyles } = config;
		headerRows = headerRows || [];
		// headerColumns = headerColumns || [];
		//const _thStyle = headerCellStyle ? this.props.data.getStyle(styles[headerCellStyle]) : _tdStyle;
		const headerStyle = config.headerStyle ? getStyle(styles[config.headerStyle]) : null;
		const footerStyle = config.footerStyle ? getStyle(styles[config.footerStyle])  : null;
		if (footerStyle)
			footerStyle.paddingTop = config.cellVerticalPadding + "pt";

		const tableStyle = {
			width: tableWidth+'pt'
		};
		if (tableStyles) {
			const lookup = getStyle(styles[tableStyles], true);
			Object.assign(tableStyle, lookup);
		}

		return (
			<div>
				{/* Header */}
				{header && (
					<div className="table-header" style={headerStyle}>
						{header}
					</div>
				)}
				{/* Table */}
				<table style={tableStyle}>
					<tbody>
						{this.props.tableData.map((tableRow, trIndex) => {
							const isHeader = headerRows.includes(trIndex);
							let rowStyle = (rowStyles && rowStyles[trIndex] && getStyle(styles[rowStyles[trIndex]], true)) || {};
							if (otherRowStyle && otherRowStyle[trIndex]) {
								rowStyle = { ...rowStyle, ...otherRowStyle[trIndex] };
							}
							const heightStyle = Object.assign({}, baseStyle, rowStyle);
							let height = heightStyle.height;
							const heightProp = {};
							if (height) { 
								heightProp.height = height;
							}
							return <tr key={trIndex}>
								{tableRow.map((tableData, tdIndex) => {
									const colStyle = columnStyles && columnStyles[tdIndex] && getStyle(styles[columnStyles[tdIndex]], true);

									const cellStyle = cellStyles && cellStyles[trIndex] && cellStyles[trIndex][tdIndex];
									const tdStyle = {};
									if (cellStyle) {
										if (cellStyle.includes("BOLD")) {
											tdStyle.fontWeight = "bold";
										}
										if (cellStyle.includes("ITALIC")) {
											tdStyle.fontStyle = "italic";
										}
									}
									if (isHeader) {
										Object.assign(tdStyle, baseStyle, colStyle, rowStyle);
									} else {
										Object.assign(tdStyle, baseStyle, rowStyle, colStyle);
									}
									Object.assign(tdStyle, {
										width: config.columnWidths[tdIndex]+"pt",
										textAlign: config.columnJustify[tdIndex]
									}, heightProp);

									return (
										<td
											style={tdStyle}
											key={tdIndex}
										>
											{tableData}
										</td>
									)
								})}
							</tr>
						})}
					</tbody>
				</table>
				{/* Footer */}
				{footer && (
					<div style={footerStyle} className="table-source-text">
						{footer}
					</div>
				)}
			</div>
		);
	}}

export default TableImpl;
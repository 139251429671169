import React, { Component } from "react";
import LogoSrc from "./craigs-logo-short.png";

class LogoShort extends Component {
	render() {
		const { className, id, onClick, size } = this.props;
		const classNames = ["logo", className].filter((cn) => !!cn).join(" ");
		return <img src={LogoSrc} alt="logo" id={id} className={classNames} onClick={onClick} style={{width: size ? size : 80}} />;
	}
}

export default LogoShort;
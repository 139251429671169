import React, { useRef, useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import useWindowDimensions, { usePdf } from '../../../utils/Utils';
import Button from '../../buttons/Button';
import FlexWrapper from '../../misc/FlexWrapper';

const PDFViewer = ({ id, centered, scale, fullHeight, fullWidth }) => {
	const [page, setPage] = useState(1);
	const [styles, setStyles] = useState({});
	const canvasRef = useRef(null);

	const { width } = useWindowDimensions();
	const isMobile = width < 1000;

	const pdfTrigger = usePdf({
		path: `/portal/publication/${id}/pdf`,
		page,
		canvasRef,
		scale: scale
	});

	const totalPages = pdfTrigger?.pdfDocument?.numPages || 1;

	useEffect(() => {
		let styleSetup = {};
		if (centered) {
			styleSetup.marginLeft = 'auto';
			styleSetup.marginRight = 'auto';
			styleSetup.marginTop = '1rem';
		}
		if (!isMobile) {
			styleSetup.width = 'fit-content';
		}
		if (fullHeight) {
			styleSetup.height = '100%';
		}
		if (fullWidth) {
			styleSetup.width = '100%';
		}
		setStyles(styleSetup);
	}, [isMobile]);

	let touchStartX = 0;
	let touchEndX = 0;

	const handleTouchStart = (e) => {
		touchStartX = e.touches[0].clientX;
	};

	const handleTouchEnd = (e) => {
		touchEndX = e.changedTouches[0].clientX;

		const swipeDistance = touchEndX - touchStartX;
		const minSwipeDistance = 50;

		if (swipeDistance > minSwipeDistance) {
			if (page > 1) {
				setPage(page - 1);
			}
		} else if (swipeDistance < -minSwipeDistance) {
			if (page < totalPages) {
				setPage(page + 1);
			}
		}
	};

	useEffect(() => {
		const canvas = canvasRef.current;

		if (isMobile) {
			canvas.addEventListener('touchstart', handleTouchStart);
			canvas.addEventListener('touchend', handleTouchEnd);

			return () => {
			canvas.removeEventListener('touchstart', handleTouchStart);
			canvas.removeEventListener('touchend', handleTouchEnd);
			};
		}
	}, [isMobile, page, totalPages]);

	return (
		<div style={styles}>
			<FlexWrapper gap="m" className="mb-s" justify="center">
				<Button
					variant="transparent"
					className={'btn-50'}
					disabled={page <= 1}
					onClick={() => setPage(page - 1)}
				>
					<ChevronLeft />
					Prev
				</Button>
				<div style={{ width: '60px', textAlign: 'center' }}>
					{`${page}/${totalPages}`}
				</div>
				<Button
					variant="transparent"
					className={'btn-50'}
					disabled={page >= totalPages}
					onClick={() => setPage(page + 1)}
				>
					Next
					<ChevronRight />
				</Button>
			</FlexWrapper>
			<canvas className="shadow-default" ref={canvasRef} />
		</div>
	);
};

export default PDFViewer;

import React, { forwardRef } from 'react';
import "./Card.scss";

const Card = forwardRef(({children, className, style, onClick, spacing}, ref) => {
	const handleClick = (e) => {
		if (onClick && typeof onClick === 'function') {
			onClick(e);
		}
	}

	return (
		<div ref={ref} onClick={handleClick} className={`card-wrapper border-default${className ? ' ' + className : ''} ${spacing ? "p-" + spacing : "pt-m pb-m pl-s pr-s"}`} style={style} onClick={onClick}>
			{children}
		</div>
   );
})

export default Card;
import React, { forwardRef, useEffect, useState } from 'react';
import FlexWrapper from '../misc/FlexWrapper';
import ResearchCard from '../cards/ResearchCard';
import { useStorage } from '../../utils/Utils';
import { useNavigate } from 'react-router-dom';
import NoResultsMessage from '../shared/prompts/NoResultsMessage';

const MobileResearchList = forwardRef(({ research, onClick, hideDownloadIcons, grouping, hideImage, hideWatchlistIcon}, ref) => {
	const navigate = useNavigate();
	return (
		<FlexWrapper direction="column" gap="m" fullWidth>

			{research && research.length > 0 ? research.map((re, idx) => {
				return <ResearchCard ref={idx == 0 ? ref : null} hideWatchlistIcon={hideWatchlistIcon} grouping={grouping ? grouping : re.template === "Sector Note" ? "sectors" : "tickers"} unread={!re.read} onClick={() => onClick(re, navigate)} hideDownloadIcons={hideDownloadIcons} hideImage={hideImage} research={re} key={idx} />
			})
			: <NoResultsMessage subMessage="Please try changing your search criteria." noIcon />
		}
		</FlexWrapper>
	);
})

export default MobileResearchList;
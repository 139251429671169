import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '../../components/popups/Tooltip';
import './IconLink.scss'

const IconLink = ({ icon, className, path, onClick, label }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleClick = () => {
		if (path) {
			navigate(path)
		}
		if (onClick) {
			onClick()
		}
	}

	return (
		<Tooltip disabled={!label} placement="right" label={label} delay={1000}>
			<div className={`link-container${location.pathname === path ? " current-route" : ""}${className ? " " + className : ""}`} onClick={() => handleClick()}>
				{icon}
			</div>
		</Tooltip>
	);
}

export default IconLink
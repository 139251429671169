import React from 'react';
import IconLink from './IconLink';
import { BookOpen, Home, LogOut, Settings, Users } from 'react-feather';
import SideBarWrapper, { SideBarIconGroup } from './SideBarWrapper';
import { analytics, useRemote } from '../../utils/Utils';
import { useNavigate } from 'react-router-dom';

const SideBarLeft = () => {
	const remote = useRemote()
	const navigate = useNavigate()
	const doLogOut = () => {
		analytics.track("logOut").then(() => {
			navigate("/")
			remote.logout()
		})
	} 
	return (
		<SideBarWrapper borderRight left>
			<SideBarIconGroup divider>
				<IconLink
					icon={<Home />}
					label="Dashboard"
					path="/"
				/>
				<IconLink
					icon={<BookOpen />}
					label="Library"
					path="/library"
				/>
				<IconLink
					icon={<Users />}
					label="Analysts"
					path="/analysts"
				/>
				<IconLink
					icon={<Settings />}
					label="Settings"
					path="/settings"
				/>
			</SideBarIconGroup>
			<SideBarIconGroup>
				<IconLink
					icon={<LogOut />}
					label="Logout"
					onClick={() => doLogOut()}
				/>
			</SideBarIconGroup>
		</SideBarWrapper>
	)
};
export default (SideBarLeft);
import React, { forwardRef } from 'react';
import FlexWrapper from '../misc/FlexWrapper';
import TickerSectorItem from '../misc/TickerSectorItem';
import Card from '../cards/Card';
import { useNavigate } from 'react-router-dom';
import { watched } from '../../utils/Utils';

const MobileTickerList = forwardRef(({ tickers, allWatched, onClick, me }, ref) => {
	const navigate = useNavigate();

	const navigateByGrouping = (data) => {
		const group = data.grouping;
		switch (group) {
			case "analysts":
				data.name ? navigate(`/analysts/${data.name}`) : navigate(`/analysts`);
				break;
			case "tickers":
				data.ric && navigate(`/company/${data.ric}`);
				break;
			case "sectors":
				data.path && navigate(data.path);
				break;

			default:
				break;
		}
	};

	return (
		<FlexWrapper direction="column" gap="s" fullWidth ref={ref}>
			{tickers &&
				tickers.map((ticker, idx) => {
					let { grouping } = ticker
					return (
						<Card key={`mobile-watchlist-${idx}`} style={{ cursor: "pointer" }} onClick={() => navigateByGrouping(ticker)}>
							<TickerSectorItem
								onWatchlist={allWatched || watched(me, grouping === "tickers" ? ticker.ric : ticker.groupName, ticker.path)}
								unread={ticker && ticker.unread ? true : false}
								isMobile
								label={ticker.name}
								extensionLabel={ticker.ric}
								image={ticker.logo}
								key={idx}
								onClick={(me) => onClick(me)}
								assetId={grouping === "tickers" ? ticker.ric : ticker.groupName}
								grouping={ticker.grouping}
								watch={ticker.watch}
								path={ticker.path}
							/>
						</Card>
					);
				})}
		</FlexWrapper>
	);
});

export default MobileTickerList;
import React from 'react';
import { BookOpen, Home, LogOut, Settings, TrendingUp, Users, X } from 'react-feather';
import Divider from '../../../components/misc/Divider';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import Logo from '../../../components/misc/Logo';
import ProfileImage from '../../../components/misc/ProfileImage';
import SidebarExtensionLinkList from '../../../components/misc/SideBarExtensionLinkList';
import OverlayWrapper from '../../../components/popups/OverlayWrapper';
import "./MobileMenu.scss";
import { useNavigate } from 'react-router-dom';
import { analytics, useRemote } from '../../../utils/Utils';

const MobileMenu = ({ user, onClick }) => {
	const remote = useRemote()
	const navigate = useNavigate()
	return (
		<OverlayWrapper id="mobile-menu" onClick={onClick}>
			<FlexWrapper gap="s" direction="column" align="flex-start" wrap className="mobile-menu-wrapper colour-bg-neutral-light p-m">
				<FlexWrapper fullWidth className="mobile-menu-header" justify="space-between">
					<Logo black />
					<X />
				</FlexWrapper>
				<Divider />
				<FlexWrapper gap="s">
					<ProfileImage user={user} />
					<FlexWrapper wrap>
						<div className="truncate profile-name">{user.firstName + " " + user.lastName}</div>
						<div className="profile-email truncate">{user.email}</div>
					</FlexWrapper>
				</FlexWrapper>
				<Divider />
				<SidebarExtensionLinkList
					links={[
						{ icon: <Home />, name: "Dashboard", path: "/" },
						{ icon: <BookOpen />, name: "Library", path: "/library" },
						{ icon: <Users />, name: "Analysts", path: "/analysts" },
						{ icon: <Settings />, name: "Profile/Settings", path: "/settings" },
					]}
				/>
				<Divider />
				<div onClick={() => {
					analytics.track("logOut").then(() => {
						navigate("/")
						remote.logout()
					})
				}}>
					<SidebarExtensionLinkList links={[{ icon: <LogOut />, name: "Logout", path: "/logout" }]} />
				</div>
			</FlexWrapper>
		</OverlayWrapper>
	);
}

export default MobileMenu;
import React from "react";

const FixedLabel = (props) => {

	const {config, styles, getStyle, getValue} = props;

	return <p style={getStyle(styles[config.style])}>{getValue(config.value.value)}</p>
}

export default FixedLabel;

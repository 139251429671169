import React from 'react';
import { BookOpen } from 'react-feather';
import EmptyStateMessage from './EmptyStateMessage';


const NoResearchAnalystMessage = () => {

	return (
		<EmptyStateMessage
			icon={<BookOpen className="transition-fade-in" />}
			header="Analyst is currently not covering any companies"
			subHeader={"Companies covered and research published by this analyst will appear here when available."}
		/>
	);
}

export default NoResearchAnalystMessage;
import React, { useEffect, useRef, useState } from 'react';
import MobileResearchList from '../../../components/lists-and-tables/MobileResearchList';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../../layout/grid/page/Page';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import ResearchList from '../../../components/lists-and-tables/ResearchList';
import { analytics } from '../../../utils/Utils';
import DetailSectionHeader from '../../../components/shared/detail-section/DetailSectionHeader';
import { useNavigate } from 'react-router-dom';
import MobileTickerList from '../../../components/lists-and-tables/MobileTickerList';
import useWindowDimensions, { useRemote, useStorage, goToResearch, useWatchList } from '../../../utils/Utils';
import TickerList from '../../../components/lists-and-tables/TickerList';
import MobileTabs from '../../../components/buttons/MobileTabs';

const HomePage = ({changeRoute}) => {
	const [activeTab, setActiveTab] = useState("new");
	const [tickers, setTickers] = useState([]);
	const [watchedTickers, setWatchedTickers] = useState([]);
	const [research, setResearch] = useState([])
	const [analysts, setAnalysts] = useState([])
	const [sectors, setSectors] = useState([])
	const [loading, setLoading] = useState(true)

	const { width } = useWindowDimensions();
	const isMobile = width < 1000;
	const remote = useRemote();
	const storage = useStorage();
	const navigate = useNavigate();
	const { me, handleWatchListClick } = useWatchList()
	const { track, page } = analytics;

	const contentDivRef = useRef(null);



	const watched = (me && me.watched) || [];

	useEffect(() => {
		window.scrollTo(0, 0);
		Promise.all([
			storage.refresh('/portal/dashboard'),
			storage.getOrFetch('/crm/tickers'),
			storage.getOrFetch('/portal/analysts'),
		])
			.then(([researchResponse, tickersResponse, analystsResponse]) => {
				setResearch(researchResponse)
				setTickers(tickersResponse)
				setAnalysts(analystsResponse)
				setLoading(false)
			})
		page()
		storage.watch('/portal/dashboard', setResearch)
		return storage.unwatch('/portal/dashboard')
	}, [])

	useEffect(() => {
		const combinedWatchlist = []
		//companies
		if (me && me.watched && tickers) {
			let arr = []
			me.watched.tickers.forEach((w) => {
				let ticker = tickers.find((t) => t.ric === w)
				if (ticker) arr.push({ ...ticker, logo: { url: ticker.logo, ticker: ticker.ric } })
			})
			arr.forEach((el) => { combinedWatchlist.push({ ...el, grouping: "tickers" }) });
		}
		//analysts
		if (me && me.watched && analysts) {
			let arr = []
			me.watched.analysts.forEach((a) => {
				let analyst = analysts.find((analyst) => analyst.email === a)
				if (analyst) arr.push(analyst)
			})
			arr.forEach((el) => { combinedWatchlist.push({ ...el, grouping: "analysts" }) });
		}
		//sectors
		if (me && me.watched) {

			let arr = [];
			if (me.watched.group) {
				Promise.all(me.watched.group.map((sector) => {
					return remote.get(`/portal/group/${sector}/information`)
				})
				).then((responseArray) => {
					responseArray.forEach(res => {
						arr.push({ groupName: res.ticker, name: res.ticker, logo: { url: res.logo }, path: res.path });
					})
					arr.forEach((el) => { combinedWatchlist.push({ ...el, grouping: "sectors" }) });
					setWatchedTickers(combinedWatchlist);
				})
			} else {
				setWatchedTickers(combinedWatchlist);
			}
		}

	}, [me && me.watched && me.watched.analysts, me && me.watched && me.watched.group, me && me.watched && me.watched.tickers, loading])

	const getWatchListLength = () => {
		let length = 0
		if (watched.tickers) length += watched.tickers.length
		if (watched.group) length += watched.group.length
		return length
	}

	const scrollToTop = () => {
		if (contentDivRef.current) {
			let element = contentDivRef.current
			const elementPosition = element.getBoundingClientRect().top;
			const offsetPosition = elementPosition - 100;

			window.scrollBy({
				top: offsetPosition,
				behavior: "smooth"
			});
		}
	};

	useEffect(() => {
		scrollToTop()
	}, [activeTab])
	

	return (
		<Page id={'dashboard-page'} fixedBanner>
			<PageContentBanner divider direction="column">
				<h2>
					Welcome,{" "}
					<span className="colour-text-brand" >
						{me && me.firstName}
					</span>
				</h2>
				<p className="banner-subheader colour-text-label-grey mb-none mt-s">
					The information on this portal has been specifically designed to support our Institutional Clients with our latest research data, insights and models.
					<br />
					View our latest research below. To view our models and search for past research{" "}
					<span className="cursor-pointer colour-text-primary text-link hover-opacity-50" onClick={() => navigate("library")}>
						click here.
					</span>
				</p>
			</PageContentBanner>
			<PageContentBodyGrid className="mobile-flex" rowGap="xl" gridTemplateRows="1fr" gridColCount="12">
				{isMobile ? (
					<>
						<PageGridItem col="1 / span 12" style={{ position: "sticky", top: width >= 992 ? "-320px" : "56px", zIndex: 1 }} id="dashboard-mobile-tabs">
							<MobileTabs
								tabs={[
									{
										label: "What's New",
										onClick: () => {setActiveTab("new")},
										active: activeTab === "new"
									},
									{
										label: "Watchlist",
										onClick: () => {setActiveTab("watchlist")},
										active: activeTab === "watchlist"
									},
								]}
							/>
						</PageGridItem>
						{activeTab === "new" ? (
							<PageGridItem container col="1 / span 12" style={{ zIndex: 0 }}>
								<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
									<PageGridItem col="1 / span 6">
										<DetailSectionHeader header="What's New" />
									</PageGridItem>
									{/* Research */}
									<PageGridItem col="1 / span 6">
										<MobileResearchList ref={contentDivRef} onClick={goToResearch} research={research} hideDownloadIcons hideWatchlistIcon />
									</PageGridItem>
								</PageContentBodyGrid>
							</PageGridItem>
						) : (
							<PageGridItem container col="1 / span 12" style={{ zIndex: 0 }}>
								<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid style={{ gridColumn: "1 / span 6", gridRow: 1 }}>
									<PageGridItem col="1 / span 6">
										<DetailSectionHeader header={`Watchlist (${getWatchListLength()})`} />
									</PageGridItem>
									<PageGridItem col="1 / span 6">
										<MobileTickerList ref={contentDivRef} allWatched tickers={watchedTickers} onClick={handleWatchListClick} me={me} />
									</PageGridItem>
								</PageContentBodyGrid>
							</PageGridItem>
						)}
					</>
				) : (
					<>
						<PageGridItem container col="1 / span 8">
							<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
								<PageGridItem col="1 / span 6">
									<DetailSectionHeader header="What's New" />
								</PageGridItem>
								{/* Research */}
								<PageGridItem col="1 / span 6">
									<ResearchList research={research} />
								</PageGridItem>
							</PageContentBodyGrid>
						</PageGridItem>
						{/* Ticker Watchlist */}
						<PageGridItem container col="9 / span 4">
							<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid style={{ gridColumn: "1 / span 6", gridRow: 1 }} divider="left">
								<PageGridItem col="1 / span 6">
									<DetailSectionHeader header={`Watchlist (${getWatchListLength()})`} />
								</PageGridItem>
								<PageGridItem col="1 / span 6">
									<TickerList tickers={watchedTickers} allWatched onClick={handleWatchListClick} />
								</PageGridItem>
							</PageContentBodyGrid>
						</PageGridItem>
					</>
				)}
			</PageContentBodyGrid>
		</Page>
	);
}

export default HomePage;